export default ({ app }, inject) => {
  // let hostInsider = 'https://www.ofm.co.th'
  // let objInsider = {}
  // let itemsInsider = []
  // let listsInsider = []
  // let basketItems = {}
  // let catagoriesInsider = []
  // let userInsider = {}

  inject('_insiderUser', () => {
    // objInsider = {}
    // itemsInsider = []
    // listsInsider = []
    // basketItems = {}
    // catagoriesInsider = []
    // userInsider = {}
    // const userInfo = app.store.getters['user/userInfo']
    // const profileType = app.store.getters['user/profileType']
    // let first_name = ''
    // let surname = ''
    // let phone_number = ''
    // let consent = false
    // let default_language = ''
    // if (Object.keys(userInfo).length) {
    //   if (userInfo.hasOwnProperty('first_name')) {
    //     first_name = userInfo.first_name[app.store.state.locale]
    //   }
    //   if (userInfo.hasOwnProperty('last_name')) {
    //     surname = userInfo.last_name[app.store.state.locale]
    //   }
    //   if (userInfo.hasOwnProperty('phones')) {
    //     if (userInfo.phones.find((a) => a.type === 'Mobile')) {
    //       phone_number = userInfo.phones.find((a) => a.type === 'Mobile').value
    //     }
    //   }
    //   if (userInfo.hasOwnProperty('consent')) {
    //     consent =
    //       userInfo.consent !== null ? userInfo.consent.marketing_status : false
    //   }
    //   if (userInfo.default_language === 'th') {
    //     default_language = 'th-th'
    //   } else if (userInfo.default_language === 'en') {
    //     default_language = 'en-us'
    //   }
    // }
    // const insiderUser = {
    //   uuid: userInfo.user_open_id || '',
    //   gdpr_optin: true,
    //   name: first_name,
    //   surname: surname,
    //   username: userInfo.email || '',
    //   email: userInfo.email || '',
    //   phone_number: phone_number,
    //   language: default_language,
    //   returning: true,
    //   custom: {
    //     customerType: profileType,
    //   },
    //   email_optin: consent,
    //   sms_optin: consent,
    // }
    // userInsider = insiderUser
  })

  inject('_onGetInsider', (obj) => {
    // app.$_insiderUser()
    // if (userInsider) {
    //   objInsider.user = { ...userInsider }
    // }
    // if (obj.page) {
    //   objInsider.page = { ...obj.page }
    // }
    // if (obj.product) {
    //   objInsider.product = { ...obj.product }
    // }
    // window.insider_object = objInsider
  })

  inject('_onInsiderBasket', async (basket) => {
    // app.$_insiderUser()
    // objInsider.user = { ...userInsider }
    // objInsider.page = { ...basket.page }
    // basketItems = {
    //   currency: 'THB',
    //   total: basket.total || 0,
    //   vouchers: [basket.vouchers || ''],
    //   voucher_discount: basket.voucher_discount || 0,
    //   shipping_cost: basket.shipping_cost || 0,
    //   line_items: [],
    // }
    // await Promise.all(
    //   basket.line_items.map(async (e) => {
    //     if (e?.categories) {
    //       e.taxonomy = []
    //       if (!e.categories) {
    //         if (e.categories.length > 0) {
    //           e.categories.forEach((tax) => {
    //             e.taxonomy.push(tax.display_name[app.store.state.locale])
    //           })
    //         }
    //       }
    //     }
    //   })
    // )
    // basketItems.line_items = basket.line_items
    //   .filter((e) => e.is_selected)
    //   .map((item) => {
    //     return {
    //       product: {
    //         id: item.sku_code,
    //         name: item.product_name[app.store.state.locale],
    //         currency: 'THB',
    //         unit_price: item.retail_price.include_vat,
    //         unit_sale_price: item.price_include_vat,
    //         url:
    //           hostInsider +
    //           app.i18n.path(
    //             `product/${item.product_name[app.store.state.locale]}-p.${
    //               item.sku_code
    //             }`
    //           ),
    //         stock: item.onhand,
    //         product_image_url: item.images.some((q) => q.size === 'thumbnail')
    //           ? item.images.find((q) => q.size === 'thumbnail').url
    //           : '',
    //         taxonomy: item.taxonomy,
    //       },
    //       quantity: item.qty,
    //       subtotal: item.qty * item.price_include_vat,
    //     }
    //   })
    // objInsider.basket = basketItems
    // window.insider_object = objInsider
  })

  inject('_onInsiderResult', async (items) => {
    // let taxonomy = []
    // let itemsInsider = []
    // let listsInsider = []
    // if (items?.taxonomy && items?.taxonomy.length > 0) {
    //   items.taxonomy.forEach((tax) => {
    //     taxonomy.push(tax)
    //   })
    // }
    // console.log('taxonomy', taxonomy)
    // if (items?.result) {
    //   itemsInsider = items.result.map((item) => {
    //     return {
    //       id: item.skus[0]?.sku_code,
    //       name: item.skus[0]?.product_names[0]?.display_name[
    //         app.store.state.locale
    //       ],
    //       taxonomy: taxonomy,
    //       currency: 'THB',
    //       unit_price: item.skus[0]?.retail_price.include_vat,
    //       unit_sale_price: item.skus[0]?.price_include_vat,
    //       url:
    //         hostInsider +
    //         app.i18n.path(
    //           `product/${
    //             item.skus[0]?.meta.seo_name[app.store.state.locale]
    //           }-p.${item.skus[0]?.sku_code}`
    //         ),
    //       stock: item.skus[0]?.onhand,
    //       product_image_url: item.skus[0]?.images.some(
    //         (q) => q.size === 'thumbnail'
    //       )
    //         ? item.skus[0]?.images.find((q) => q.size === 'thumbnail').url
    //         : '',
    //     }
    //   })
    // }
    // if (items?.lists) {
    //   listsInsider = items.lists.map((item) => {
    //     return {
    //       id: item.productId,
    //       name: item.productName[app.store.state.locale],
    //       taxonomy: taxonomy,
    //       currency: 'THB',
    //       unit_price: item.retail_price.include_vat,
    //       unit_sale_price: item.price_include_vat,
    //       url:
    //         hostInsider +
    //         app.i18n.path(
    //           `product/${item.seoMeta[app.store.state.locale]}-p.${
    //             item.productId
    //           }`
    //         ),
    //       stock: item.onhand,
    //       product_image_url: item.productImg,
    //     }
    //   })
    // }
    // setTimeout(() => {
    //   objInsider.listing = Object.assign({
    //     items: [
    //       ...(itemsInsider || []),
    //       ...(listsInsider || []),
    //       ...(catagoriesInsider || []),
    //     ],
    //   })
    //   window.insider_object = objInsider
    // }, 50)
  })

  inject('_onInsiderCategories', async (itemsCategories) => {
    // catagoriesInsider = catagoriesInsider.filter(
    //   (e) => e.categoriesName !== itemsCategories[0].categoriesName
    // )
    // itemsCategories.forEach((item) => {
    //   catagoriesInsider.unshift({
    //     categoriesName: item.categoriesName,
    //     id: item.productId,
    //     name: item.productName[app.store.state.locale],
    //     currency: 'THB',
    //     unit_price: item.retail_price,
    //     unit_sale_price: item.price_include_vat,
    //     url:
    //       hostInsider +
    //       app.i18n.path(
    //         `product/${item.productName[app.store.state.locale]}-p.${
    //           item.productId
    //         }`
    //       ),
    //     stock: item.onhand,
    //     product_image_url: item.productImg,
    //   })
    // })
    // objInsider.listing = Object.assign({
    //   items: [
    //     ...(itemsInsider || []),
    //     ...(listsInsider || []),
    //     ...(catagoriesInsider || []),
    //   ],
    // })
    // window.insider_object = objInsider
  })

  inject('_insiderTransaction', async (obj) => {
    // app.$_insiderUser()
    // objInsider.user = Object.assign(userInsider)
    // objInsider.page = Object.assign(obj.page)
    // let transaction = {
    //   order_id: obj.transaction.order_id,
    //   currency: 'THB',
    //   total: obj.transaction.total,
    //   payment_type: obj.transaction.payment_type,
    //   vouchers: obj.transaction.vouchers,
    //   voucher_discount: obj.transaction.voucher_discount,
    //   shipping_cost: obj.transaction.shipping_cost,
    //   delivery: {
    //     country: obj.transaction.delivery.country,
    //     city: obj.transaction.delivery.city,
    //     district: obj.transaction.delivery.district,
    //   },
    //   line_items: [],
    // }
    // await obj.transaction.line_items.forEach((e) => {
    //   if (e?.categories) {
    //     e.taxonomy = []
    //     if (!!e.categories) {
    //       if (e.categories.length > 0) {
    //         e.categories.forEach((tax) => {
    //           e.taxonomy.push(tax.display_name[app.store.state.locale])
    //         })
    //       }
    //     }
    //   }
    // })
    // transaction.line_items = obj.transaction.line_items.map((item) => {
    //   return {
    //     product: {
    //       id: item.sku_code,
    //       name: item.product_name[app.store.state.locale],
    //       currency: 'THB',
    //       unit_price: item.price_incvat,
    //       unit_sale_price: item.price_incvat,
    //       url: '',
    //       stock: '',
    //       taxonomy: item.taxonomy,
    //       product_image_url: item.product_image_url,
    //     },
    //     quantity: item.quantity,
    //     subtotal: item.quantity * item.price_incvat,
    //   }
    // })
    // objInsider.transaction = Object.assign(transaction)
    // window.insider_object = objInsider
  })
}
