export const TypeEventInsightsAlgoliaEnum = {
  CLICKAFTER: 'clickedObjectIDsAfterSearch',
  CLICK: 'clickedObjectIDs',
  CLICKFILTER: 'clickedFilters',
  CONVERTEDAFTER: 'convertedObjectIDsAfterSearch',
  CONVERTEDFILTER: 'convertedFilters',
  CONVERTED: 'convertedObjectIDs',
  VIEWEDFILTER: 'viewedFilters',
  VIEWED: 'viewedObjectIDs',
}

export const EventNameInsightsAlgoliaEnum = {
  PRODUCTSBARAFTERSEARCH: 'PLP: Click - Products bar after Search',
  PRODUCTSAFTERSEARCH: 'PLP: Click - Products after Search',
  BRANDSAFTERSEARCH: 'PLP: Click - Brands after Search',
  ADDCARTAFTERSEARCH: 'PLP: Click - AddCart after Search',
  WISHLISTAFTERSEARCH: 'PLP: Click - Wishlist after Search',
  CLICKFILTERS: 'PLP: Click - Filters',
  VIEWFILTERS: 'PLP: View - Filters',
  PRODUCTADDEDTOCARTAFTERSEARCH: 'PDP: Product Added to Cart after Search',
  PRODUCTADDEDTOWISHLISTAFTERSEARCH:
    'PDP: Product Added to Wishlist after Search',
  PRODUCTADDEDTOCART: 'PDP: Product Added to Cart',
  PRODUCTADDEDTOWISHLIST: 'PDP: Product Added to Wishlist',
  PRODUCTPAGE: 'PDP: View - Product Page',
  CLICKADDEDTOCART: 'PLP: Click - AddCart',
  CLICKADDEDTOWISHLIST: 'PLP: Click - Wishlist',
  CLICKPRODUCTS: 'PLP: Click - Products',
}

export const TriggerInsightsAlgoliaEnum = {
  SEARCHBARVIEWPDP: 'SEARCHBARVIEWPDP',
  SEARCHVIEWPDP: 'SEARCHVIEWPDP',
  SEARCHPLPADDCART: 'SEARCHPLPADDCART',
  SEARCHPLPWISHLIST: 'SEARCHPLPWISHLIST',
  SEARCHPDPADDCART: 'SEARCHPDPADDCART',
  SEARCHPDPWISHLIST: 'SEARCHPDPWISHLIST',
  CLICKFILTERS: 'CLICKFILTERS',
  VIEWPLP: 'VIEWPLP',
  VIEWPDP: 'VIEWPDP',
  NONSEARCHPLPADDCART: 'NONSEARCHPLPADDCART',
  NONSEARCHPLPWISHLIST: 'NONSEARCHPLPWISHLIST',
  NONSEARCHVIEWPDP: 'NONSEARCHVIEWPDP',
  NONSEARCHPDPADDCART: 'NONSEARCHPDPADDCART',
  NONSEARCHPDPWISHLIST: 'NONSEARCHPDPWISHLIST',
}

export const TriggerTypeInsightsAlgoliaEnum = {
  SEARCH: 'SEARCH',
  SEARCHNOTPOSITION: 'SEARCHNOTPOSITION',
  NONSEARCH: 'NONSEARCH',
  FILTER: 'FILTER',
}
export const IndexInsightsAlgoliaEnum = {
  PRODUCT: 'products',
  PRODUCTPROMOTION: 'product_promotion',
  PRODUCTNEWARRIVAL: 'product_new_arrival',
  PRODUCTRATING: 'product_rating',
  PRODUCTPRICEASC: 'product_price_asc',
  PRODUCTPRICEDESC: 'product_price_desc',
}
