import * as core from '@/plugins/service/core'

export const productProvider = {
  async getDepartment(deptId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Departments/${deptId}`
    )
    return data
  },
  async productsSearchEngine(params, body) {
    const data = core.create(
      `${
        process.env.NUXT_ENV_SERVICE_PRODUCT
      }api/v2/ProductsSearchEngine?${params.toString()}`,
      body,
      null,
      false
    )
    return data
  },
  async shopSearchEngine(params) {
    const data = core.fetch(
      `${
        process.env.NUXT_ENV_SERVICE_PRODUCT
      }api/v2/ShopsSearchEngine?${params.toString()}`,

      null,
      false
    )
    return data
  },
  async getProductList(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/filters`,
      params,
      null,
      false
    )
    return data
  },
  async getProductSpecialCatalogList(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductSpecialCatalogs`,
      params,
      null,
      false
    )
    return data
  },
  async getProductById(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Products/ByID`,
      params
    )
    return data
  },
  async getProductBySkuCode(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Products/BySkuCode`,
      params,
      null,
      false
    )
    return data
  },
  async getProductSimilarBySkuCode(sku_code, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductSkus/${sku_code}/similar`,
      params,
      null,
      false
    )
    return data
  },
  async getCategories(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories`,
      params,
      null,
      false
    )
    return data
  },
  async getCategoryById(id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Categories/${id}`,
      null,
      null,
      false
    )
    return data
  },
  async getCategoriesChilds(id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/${id}/childs`
    )
    return data
  },
  async getProductLevel(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Products`,
      params
    )
    return data
  },
  async getBrand(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/brands`,
      params,
      null,
      false
    )
    return data
  },
  async getBrandProductSpecialCatalog(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSpecialCatalogs/brands`,
      params,
      null,
      false
    )
    return data
  },
  async getAttribute(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/attributes`,
      params,
      null,
      false
    )
    return data
  },
  async getAttributeProductSpecialCatalog(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSpecialCatalogs/attributes`,
      params,
      null,
      false
    )
    return data
  },
  async createCatalogData(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs`,
      params,
      null,
      false
    )
    return data
  },
  async updateCatalogById(catalog_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}`,
      params,
      null,
      false
    )
    return data
  },
  async updateCatalogDefaultById(catalog_id, company_id, user_open_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}/defaults?company_id=${company_id}&user_open_id=${user_open_id}`,
      params,
      null,
      false
    )
    return data
  },
  async deleteCatalogById(catalog_id) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}`,
      null,
      null,
      false
    )
    return data
  },
  async getCatalogsDataById(params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/catalogs`,
      params,
      null,
      false
    )
    return data
  },
  async getProductCatalogsDataById(catalog_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}/products`,
      params,
      null,
      false
    )
    return data
  },
  async createProductCatalogsData(catalog_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}/products`,
      params,
      null,
      false
    )
    return data
  },
  async updateProductCatalogsData(catalog_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}/products`,
      params,
      null,
      false
    )
    return data
  },
  async deleteProductCatalogById(catalog_id, product_id) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/catalogs/${catalog_id}/products/${product_id}`,
      null,
      null,
      false
    )
    return data
  },

  async getUserProductHistory(userOpenId, profileId, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/UserProductHistory/${userOpenId}-${profileId}`,
      params,
      null,
      false
    )
    return data
  },
  async addUserProductHistory(body) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/UserProductHistory`,
      body,
      null,
      false
    )
    return data
  },
  async getProductSkus(sku_code) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSkus/${sku_code}`
    )
    return data
  },

  async productSkusbulkSearch(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSkus/bulkSearch`,
      params
    )
    return data
  },

  async getBrandProductHomeData(id, params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/brands/${id}/products`,
      params,
      null,
      showLoading
    )
    return data
  },
  async getHomeCategoriesData(id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/categories/${id}`,
      params,
      null,
      false
    )
    return data
  },
  async getHomeSpecificCategoriesData(params) {
    const data = core.fetchCache(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/categories/specific`,
      params,
      null,
      false
    )
    return data
  },
  async getSpecificCategoriesProductHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/categories/specific/products`,
      params,
      null,
      false
    )
    return data
  },
  async getCategoriesProductHomeData(id, params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/categories/${id}/products`,
      params,
      null,
      showLoading
    )
    return data
  },

  async getRecommendedProductHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/recommended/products`,
      params,
      null,
      false
    )
    return data
  },
  async getCategoriesAndBrandProductHomeData(categoryId, brandId, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/categories/${categoryId}/brands/${brandId}/products`,
      params,
      null,
      false
    )
    return data
  },
  async getBlogsData() {
    const data = core.fetchCache(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/blogs`,
      null,
      null,
      false
    )
    return data
  },
  async getAttributeProductHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/widget/attributes`,
      params,
      null,
      false
    )
    return data
  },
  async getwidgetCategoryData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/widget/categoryOptions`,
      params,
      null,
      false
    )
    return data
  },
  async getwidgetBrandsData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/widget/brands`,
      params,
      null,
      false
    )
    return data
  },
  async getWidgetHomePageProductsSeeAll(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/widget/products/seeall`,
      params,
      null,
      false
    )
    return data
  },
  async getWidgetProducts(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/widget/products`,
      params,
      null,
      false
    )
    return data
  },

  async getProductRelatedData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/Relates`,
      params,
      null,
      false
    )
    return data
  },
  async getCategoryBrandsData(id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Categories/${id}/bannerbrands`,
      null,
      null,
      false
    )
    return data
  },
  async getCategoryProductBestSellerData(id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Categories/${id}/products/bestseller`,
      params,
      null,
      false
    )
    return data
  },
  async getBannerBrandSeeAllData() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/bannerbrands/seeall`,
      null,
      null,
      false
    )
    return data
  },
  async getPromotionProductData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Categories/products/promotion`,
      params,
      null,
      false
    )
    return data
  },

  async headPromotion(params) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/promotion`,
      params
    )
    return data
  },
  async headRanking(params) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/ranking`,
      params
    )
    return data
  },
  async getPromotion() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/promotion/categorys`,
      null,
      null,
      false
    )
    return data
  },
  async getRanking() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/ranking/categorys`,
      null,
      null,
      false
    )
    return data
  },
  async headProductControl(params) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/productcontrol`,
      params
    )
    return data
  },
  async getDataFile(uri) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Files?uri=${uri}`
    )
    return data
  },

  async getStoreLocationDetail(params) {
    try {
      const data = core.fetch(
        `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/StoreLocation`,
        params
      )
      return data
    } catch (ex) {
      return ex
    }
  },
  async getServiceDetail(params) {
    try {
      const data = core.fetch(
        `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/StoreLocation/service`,
        params
      )
      return data
    } catch (ex) {
      return ex
    }
  },
  async create2c2pPayment(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/payments/2c2p`,
      params
    )
    return data
  },
  async paymentInquiry2C2P(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/payments/2c2p/PaymentInquiry`,
      params
    )
    return data
  },
  // installment v2
  async paymentnstallment2c2p(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/payments/2c2p/BankInstallment`,
      params
    )
    return data
  },
  async paymentnstallment2c2pValidate(params) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/payments/2c2p/Validate`,
      params
    )
    return data
  },
  async getPaymentAllBankInstallment(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/payments/2c2p/PaymentAllBankInstallment/sku/${params}`,
      null,
      null,
      false
    )
    return data
  },
  async create2c2p123Payment(body) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Orders/payment/2c2p123`,
      body
    )
    return data
  },
  async get2c2p123PaymentDetail(paymentCode) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Orders/payment/2c2p123/${paymentCode}`
    )
    return data
  },
  async updateOrderPaymentDetail(orderId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Orders/${orderId}/payment/repayment`,
      body
    )
    return data
  },
  async getRecommendProductsSeeAll(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/recommended/products/seeall`,
      params,
      null,
      false
    )
    return data
  },
  async getRecommendBrandsData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/recommended/brands`,
      params,
      null,
      false
    )
    return data
  },
  async getRecommendAttributeProductHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/recommended/attributes`,
      params,
      null,
      false
    )
    return data
  },
  // flash sale
  async getFlashSaleProductHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/flashSale/products`,
      params,
      null,
      false
    )
    return data
  },
  async getFlashSaleProductsSeeAll(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/ProductHomePage/flashSale/products/seeall`,
      params,
      null,
      false
    )
    return data
  },
  async getFlashSaleCategories(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/flashSale/category-options`,
      params,
      null,
      false
    )
    return data
  },
  async getFlashSaleBrandsData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/flashSale/brands`,
      params,
      null,
      false
    )
    return data
  },
  async getFlashSaleExpired() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductHomePage/flashSale/Expired`,
      null,
      null,
      false
    )
    return data
  },
  async getPromotionDiscountSeeAll(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/promotions/discount/product-seeall`,
      params,
      null,
      false
    )
    return data
  },
  async getPromotionDiscountList(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/promotions/discount/product`,
      params,
      null,
      false
    )
    return data
  },
  async getProductsByPromotionNo(promotion_no, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/promotions/${promotion_no}/products`,
      params,
      null,
      false
    )
    return data
  },
  async getBrandsByPromotionNo(promotion_no, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/promotions/${promotion_no}/brands`,
      params,
      false
    )
    return data
  },
  async getAttributesByPromotionNo(promotion_no, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/promotions/${promotion_no}/attributes`,
      params,
      false
    )
    return data
  },
  async getDeliveryChargeByDeliveryAreaSku(sku_code, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSkus/${sku_code}/DeliveryChargeByDeliveryArea`,
      params,
      null,
      false
    )
    return data
  },
  // async getFilterProductRelatesByDeliveryAreaSku(sku_code, params) {
  //   const data = core.fetch(
  //     `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductSkus/${sku_code}/FilterProductRelatesByDeliveryArea`,
  //     params,
  //     null,
  //     false
  //   )
  //   return data
  // },
  async getProductRelatesByDeliveryArea(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/ProductRelatesByDeliveryArea`,
      params,
      null,
      false
    )
    return data
  },
  async getFilterCategoryProductRelatesByDeliveryArea(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/ProductRelatesByDeliveryArea/Categories`,
      params,
      null,
      false
    )
    return data
  },
  async getFilterBrandProductRelatesByDeliveryArea(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/ProductRelatesByDeliveryArea/Brands`,
      params,
      null,
      false
    )
    return data
  },
  async getFilterAttributeProductRelatesByDeliveryArea(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Products/ProductRelatesByDeliveryArea/attributes`,
      params,
      null,
      false
    )
    return data
  },
  async getPromotionCoupons(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/PromotionCoupons/`,
      params,
      null,
      false
    )
    return data
  },
}
export default (context, inject) => {
  inject('productProvider', productProvider)
}
