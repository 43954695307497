import cookieparser from 'cookieparser'

export default async ({ store, req }) => {
  if (process.server) {
    if (req && req.headers && req.headers.cookie) {
      const parsedCookies = cookieparser.parse(req.headers.cookie)

      if (
        store.state?.authen?.accessToken !== process.env.NUXT_ENV_GUEST_TOKEN &&
        !parsedCookies.accessToken
      ) {
        // Await the actions
        await store.dispatch('user/removeUserProfile')
        await store.dispatch('authen/signout')
      }

      console.log('Server-Side Vuex Store:', store.state)
      console.log('Parsed Cookies:', parsedCookies)
    }
  }
  if (process.client) {
    console.log('Vuex Store:', store.state)
    window.addEventListener('pageshow', function (event) {
      const historyTraversal =
        event.persisted ||
        performance.getEntriesByType('navigation')[0]?.type === 'back_forward'

      if (historyTraversal) {
        const Cookie = process.client ? require('js-cookie') : undefined
        if (
          store.state?.authen?.accessToken !==
            process.env.NUXT_ENV_GUEST_TOKEN &&
          !Cookie.get('accessToken')
        ) {
          store.dispatch('user/removeUserProfile')
          store.dispatch('authen/signout')
        }
        console.log('Vuex Store navigation:', store.state) // Access the Vuex state
        console.log('Vuex Cookie navigation:', Cookie.get('accessToken'))
        // window.location.reload() // Reload the page
      }
    })
  }
}
