import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

export default ({ app }) => {
  extend('email', {
    ...rules.email,
    message: () => {
      return app.i18n.t('validate.email')
    },
  })
  extend('required', {
    ...rules.required,
    message: (fiedName) => {
      return app.i18n.t('validate.required').replace('{fieldName}', fiedName)
    },
  })
  extend('required_select', {
    ...rules.required,
    message: (fiedName) => {
      return app.i18n
        .t('validate.required_select')
        .replace('{fieldName}', fiedName)
    },
  })
  extend('is', {
    ...rules.is,
    message: (fiedName) => {
      return app.i18n.t('validate.is').replace('{fieldName}', fiedName)
    },
  })
  extend('validateName', {
    validate: (value) => {
      const regex = /^[a-zA-Zก-๙()\s]+$/
      let validateResult = regex.test(value)
      return validateResult
    },
    message: (fiedName) => {
      return app.i18n
        .t('validate.validate_name')
        .replace('{fieldName}', fiedName)
    },
  })
  extend('duplicateValue', {
    validate(value, { validateFlag, msg }) {
      return !validateFlag
    },
    params: ['validateFlag', 'msg'],
    message: (fieldName, objProvider) => {
      return objProvider.msg
    },
  })
  extend('validatePhone', {
    validate(value, { msg }) {
      if (value.length === 9) {
        let phonePrefix = ['02', '03', '04', '05', '07']
        let valuePrefix = value.substring(0, 2)
        console.log('valuePrefix', valuePrefix)
        let validateResult =
          phonePrefix.find((q) => q === valuePrefix) !== undefined
            ? true
            : false
        return validateResult
      } else if (value.length === 10) {
        let phonePrefix = ['06', '08', '09']
        let valuePrefix = value.substring(0, 2)
        console.log('valuePrefix', valuePrefix)
        let validateResult =
          phonePrefix.find((q) => q === valuePrefix) !== undefined
            ? true
            : false
        return validateResult
      } else return true
    },
    params: ['msg'],
    message: (fieldName, objProvider) => {
      return objProvider.msg
    },
  })
  extend('validateTaxId', {
    validate(value, { msg }) {
      if (typeof value !== 'string') value = value.toString()
      if (value.length === 13) {
        let sum = 0
        for (let index = 0; index <= 11; index++) {
          sum += parseInt(value[index]) * (13 - index)
        }
        let calculateResult = 11 - (sum % 11)
        let compareValue =
          calculateResult.toString()[calculateResult.toString().length - 1]
        return compareValue === value[12] ? true : false
      } else return true
    },
    params: ['msg'],
    message: (fieldName, objProvider) => {
      return objProvider.msg
    },
  })
  extend('validatePasswords', {
    validate: (value, { msg }) => {
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
      let validateResult = regex.test(value)
      return validateResult
    },
    params: ['msg'],
    message: (fiedName) => {
      return app.i18n
        .t('validate.validate_name')
        .replace('{fieldName}', fiedName)
    },
  })
}
