export default function ({ app, store }) {
  // Determine the script source based on a condition
  const src =
    store.getters['user/profileType'] === 'eprocurement'
      ? process.env.NUXT_ENV_SALEFORCE_SCRIPT_EPRO
      : process.env.NUXT_ENV_SALEFORCE_SCRIPT_STANDARD

  // Find if 'sf-object' script already exists in head
  const existingScript = app.head.script.find(
    (script) => script.hid === 'sf-object'
  )
  // const existingEmbed = app.head.script.find(
  //   (script) => script.hid === 'sf-script'
  // )
  if (existingScript) {
    existingScript.src = src
  }
  // if (existingEmbed) {
  //   // existingEmbed.src = ''
  //   existingEmbed.src = process.env.NUXT_ENV_SALEFORCE_EMBED
  // }
}
