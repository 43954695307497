export default ({ app, route, store, redirect }) => {
  let queryShopback = route.query
  const Cookie = process.client ? require('js-cookie') : undefined
  // Every time the route changes (fired on initialization too)
  app.router.beforeEach((to, from, next) => {
    // if (route.path.includes('/universal-link')) {
    //   return redirect('/activity/download-app')
    // }
    if (route.path.includes('/universal-link')) {
      setTimeout(() => {
        next('/activity/download-app')
      }, 4000) // หน่วงเวลา 4 วินาที
    } else {
      // next()
    }
    //do something to validate
    if (process.client) {
      // app.$setBrazeUser()
      if (
        queryShopback.transaction_id !== undefined &&
        queryShopback.utm_source !== undefined
      ) {
        let sessionShopback = {
          affiliate_source: queryShopback.utm_source,
          affiliate_transaction_id: queryShopback.transaction_id,
        }
        sessionStorage.setItem(
          'sessionShopback',
          JSON.stringify(sessionShopback)
        )
      }
      queryShopback = {}
    }

    // check insider
    // const insiderScript = app.head.script.find(
    //   (element) => element.ref === 'insiderScript'
    // )
    // insiderScript.src =
    //   app.store.getters['user/profileType'] !== null &&
    //   app.store.getters['user/profileType'] === 'eprocurement'
    //     ? '//eprocurementth.api.useinsider.com/ins.js?id=10002769'
    //     : '//eprocurementth.api.useinsider.com/ins.js?id=10006310'
    if (process.client) {
      // Clear window.insider_object before leaving the route
      window.insider_object = null
    }
    app.store.dispatch('authen/isAuthenticated')
    app.store.dispatch('authen/isGuestId')
    // const Cookie = process.client ? require('js-cookie') : undefined
    // if (!Cookie.get('openId')) {
    //   app.$clearBrazeUser()
    // }
    // micro route
    const incluceRoute = [
      'ofm-shop',
      'lang-ofm-shop',
      'ofm-shop-id',
      'lang-ofm-shop-id',
      'shop',
      'lang-shop',
      'shop-id',
      'lang-shop-id',
      'shop-search-keyword',
      'lang-shop-search-keyword',
    ]

    if (incluceRoute.includes(to?.name)) {
      if (process.client) {
        const isLocalEnv =
          process.env.NODE_ENV === 'dev-local' ||
          process.env.NODE_ENV === 'uat-local'

        if (!isLocalEnv) {
          window.location.href = to.path // This will preserve history within the Nuxt app
        }
      }
      next(false)
    } else {
      next()
    }
  })

  // app.router.afterEach((to, from) => {
  //   const entries = performance.getEntriesByType('navigation')
  //   if (entries.length > 0 && entries[0].type === 'back_forward') {
  //     window.location.reload()
  //   }
  // })
}
