import Vue from 'vue'

Vue.mixin({
  methods: {
    $_validateForm: function (form, validate, submitForm) {
      let isError = false
      if (validate) {
        Object.getOwnPropertyNames(this[validate]).forEach((element) => {
          if (
            (form ? this.$data[form][element] : this.$data[element]) === '' ||
            (form ? this.$data[form][element] : this.$data[element]) ===
              undefined
          ) {
            this.$data[validate][element] = true
            isError = true
          } else {
            this.$data[validate][element] = false
          }
        })
      }
      if (!isError) {
        this[submitForm](this.$data[form])
      }
    },
    $_clearForm(form, reload) {
      Object.getOwnPropertyNames(this[form]).forEach((element) => {
        if (element !== '__ob__') this[form][element] = ''
      })

      this[reload] = false
      this.$nextTick(() => {
        this[reload] = true
      })
    },
  },
})
