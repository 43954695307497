import * as core from '@/plugins/service/core'

export const bannerProvider = {
  // async getBannerStaticByNameOrUrl(url) {
  //   const data = core.fetch(
  //     `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/statics?url=${url}`
  //   )
  //   return data
  // },
  async getBannerStaticByNameOrUrl(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Banners/statics`,
      params
    )
    return data
  },
  async getBannerStatic(id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/statics/${id}`
    )
    return data
  },
  async getBannerCampaignByNameOrUrl(params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v2/Banners/campaigns`,
      params,null,showLoading
    )
    return data
  },
  async getBrandBannerCampaign(params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/brands`,
      params,null,showLoading
    )
    return data
  },
  async getAttributeBannerCampaign(params, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/attributes`,
      params,null,showLoading
    )
    return data
  },
}
export default (context, inject) => {
  inject('bannerProvider', bannerProvider)
}
