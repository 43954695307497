export const state = () => ({
  queryID: ''
})

export const mutations = {
  SET_QUERY_ID(state, queryID) {
    state.queryID = queryID
  },
  CLEAR_QUERY_ID(state) {
    state.queryID = ''
  }
}

export const actions = {
  set_query_id({ commit }, path) {
    commit('SET_QUERY_ID', path)
  },
  cleare_query_id({ commit }) {
    commit('CLEAR_QUERY_ID')
  },
}

export const getters = {
  setQueryID: (state) => state.queryID
}
