const insights = require('search-insights')
import {
  TypeEventInsightsAlgoliaEnum,
  EventNameInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
  TriggerTypeInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
export default ({}, inject) => {
  insights('init', {
    appId: process.env.NUXT_ENV_BASE_ALGOLIA_APP_ID,
    apiKey: process.env.NUXT_ENV_BASE_ALGOLIA_API_KEY,
  })
  console.log('insights init')
  inject('eventInsightsAlgolia', eventInsightsAlgolia)
}

function isValid(data) {
  return data !== undefined && data !== null && data !== '' ? true : false
}

function anyArrayObjectValid(data) {
  return data !== undefined && data !== null && data.length > 0 ? true : false
}

// function sendEvent(index, type, eventName, userToken, queryID, objectIds, positions) {
//   switch (type) {
//     case TypeEventInsightsAlgoliaEnum.CLICKAFTER:
//     case TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER:
//       console.log("AfterSearch");
//       if (!isValid(objectIds)) return null

//       insights(type, {
//         userToken: userToken ?? 'empty',
//         eventName: eventName,
//         index: index,
//         queryID: queryID,
//         objectIds: anyArrayObjectValid(objectIds) ? objectIds : ['-'],
//         positions: anyArrayObjectValid(positions) ? positions : [1]
//       })
//       break;
//       case TypeEventInsightsAlgoliaEnum.CLICK:
//       case TypeEventInsightsAlgoliaEnum.VIEWED:
//       case TypeEventInsightsAlgoliaEnum.CONVERTED:
//       console.log("ObjectID");

//       insights(type, {
//         userToken: userToken ?? 'empty',
//         eventName: eventName,
//         index: index,
//         objectIds: anyArrayObjectValid(objectIds) ? objectIds : ['-']
//       })
//       break;
//     case TypeEventInsightsAlgoliaEnum.VIEWEDFILTER:
//     case TypeEventInsightsAlgoliaEnum.CLICKFILTER:
//     case TypeEventInsightsAlgoliaEnum.CONVERTEDFILTER:
//       console.log("Filter");
//       insights(type, {
//         userToken: userToken ?? 'empty',
//         eventName: eventName,
//         index: index,
//         filters: anyArrayObjectValid(objectIds) ? objectIds : ['-']
//       })
//       break;
//     default:
//       console.log("Type not correct");
//       break;
//   }
// }

function sendEvent(
  triggerType,
  type,
  eventName,
  userToken,
  queryID,
  objectIdsOrFilters,
  positions,
  index
) {
  let obj = {}
  switch (triggerType) {
    case TriggerTypeInsightsAlgoliaEnum.SEARCH:
      console.log('SEARCH')
      obj = {
        userToken: userToken ?? 'empty',
        eventName: eventName,
        index: index,
        queryID: queryID,
        objectIds: anyArrayObjectValid(objectIdsOrFilters)
          ? objectIdsOrFilters
          : ['-'],
      }
      if (type !== TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER)
        obj.positions = anyArrayObjectValid(positions) ? positions : [1]

      insights(type, obj)
      break
    case TriggerTypeInsightsAlgoliaEnum.SEARCHNOTPOSITION:
      console.log('SEARCHNOTPOSITION')
      obj = {
        userToken: userToken ?? 'empty',
        eventName: eventName,
        index: index,
        queryID: queryID,
        objectIds: anyArrayObjectValid(objectIdsOrFilters)
          ? objectIdsOrFilters
          : ['-'],
      }
      if (type !== TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER)
        obj.positions = anyArrayObjectValid(positions) ? positions : [1]

      insights(type, obj)
      break
    case TriggerTypeInsightsAlgoliaEnum.NONSEARCH:
      console.log('NONSEARCH')
      insights(type, {
        userToken: userToken ?? 'empty',
        eventName: eventName,
        index: index,
        objectIds: anyArrayObjectValid(objectIdsOrFilters)
          ? objectIdsOrFilters
          : ['-'],
      })
      break
    case TriggerTypeInsightsAlgoliaEnum.FILTER:
      console.log('Filter')
      insights(type, {
        userToken: userToken ?? 'empty',
        eventName: eventName,
        index: index,
        filters: anyArrayObjectValid(objectIdsOrFilters)
          ? objectIdsOrFilters
          : ['-'],
      })
      break
    default:
      console.log('Type not correct')
      break
  }
}

export const eventInsightsAlgolia = {
  sendEvent(
    triggerType,
    userToken,
    queryID,
    objectIdsOrFilters = ['-'],
    positions = [1],
    index = 'products'
  ) {
    let triggerTypeInsights = ''
    let typeEventInsights = ''
    let eventNameInsights = ''
    index = isValid(index) ? index : 'products'
    console.log('sendEvent', index)
    switch (triggerType) {
      case TriggerInsightsAlgoliaEnum.SEARCHBARVIEWPDP:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CLICKAFTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.PRODUCTSBARAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.SEARCHVIEWPDP:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CLICKAFTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.PRODUCTSAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.SEARCHPLPADDCART:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.ADDCARTAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.SEARCHPLPWISHLIST:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.WISHLISTAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.SEARCHPDPADDCART:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCHNOTPOSITION
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER
        eventNameInsights =
          EventNameInsightsAlgoliaEnum.PRODUCTADDEDTOCARTAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.SEARCHPDPWISHLIST:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.SEARCHNOTPOSITION
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTEDAFTER
        eventNameInsights =
          EventNameInsightsAlgoliaEnum.PRODUCTADDEDTOWISHLISTAFTERSEARCH
        break
      case TriggerInsightsAlgoliaEnum.CLICKFILTERS:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.FILTER
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CLICKFILTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.CLICKFILTERS
        break
      case TriggerInsightsAlgoliaEnum.VIEWPLP:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.FILTER
        typeEventInsights = TypeEventInsightsAlgoliaEnum.VIEWEDFILTER
        eventNameInsights = EventNameInsightsAlgoliaEnum.VIEWFILTERS
        break
      case TriggerInsightsAlgoliaEnum.VIEWPDP:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.VIEWED
        eventNameInsights = EventNameInsightsAlgoliaEnum.PRODUCTPAGE
        break
      case TriggerInsightsAlgoliaEnum.NONSEARCHPLPADDCART:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTED
        eventNameInsights = EventNameInsightsAlgoliaEnum.CLICKADDEDTOCART
        break
      case TriggerInsightsAlgoliaEnum.NONSEARCHPLPWISHLIST:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTED
        eventNameInsights = EventNameInsightsAlgoliaEnum.CLICKADDEDTOWISHLIST
        break
      case TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CLICK
        eventNameInsights = EventNameInsightsAlgoliaEnum.CLICKPRODUCTS
        break
      case TriggerInsightsAlgoliaEnum.NONSEARCHPDPADDCART:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTED
        eventNameInsights = EventNameInsightsAlgoliaEnum.PRODUCTADDEDTOCART
        break
      case TriggerInsightsAlgoliaEnum.NONSEARCHPDPWISHLIST:
        triggerTypeInsights = TriggerTypeInsightsAlgoliaEnum.NONSEARCH
        typeEventInsights = TypeEventInsightsAlgoliaEnum.CONVERTED
        eventNameInsights = EventNameInsightsAlgoliaEnum.PRODUCTADDEDTOWISHLIST
        break
      default:
        break
    }

    if (
      isValid(triggerTypeInsights) &&
      isValid(typeEventInsights) &&
      isValid(eventNameInsights)
    ) {
      sendEvent(
        triggerTypeInsights,
        typeEventInsights,
        eventNameInsights,
        userToken,
        queryID,
        objectIdsOrFilters,
        positions,
        index
      )
    }

    // sendEventObjectIdsAfterSearch(type, eventName, userToken, queryID, objectIds = ['-'], positions = [1], index = 'products') {
    //   console.log('connect algolia', type, eventName, userToken, queryID, objectIds, positions, index)
    //   sendEvent(index, type, eventName, userToken, queryID, objectIds, positions)
    //   console.log('send event algolia')
    // },
    // sendEventObjectIds(type, eventName, userToken, objectIds = ['-'], index = 'products') {
    //   console.log('connect algolia', type, eventName, userToken, objectIds, index)
    //   sendEvent(index, type, eventName, userToken, null, objectIds, null)
    //   console.log('send event algolia')
    // },
    // sendEventFilters(type, userToken, queryID, filters, index = 'products') {
    //   console.log('connect algolia', type, eventName, userToken, queryID, filters, index)
    //   sendEvent(index, type, eventName, userToken, null, filters, null)
    //   console.log('send event algolia')
    // },
    // switchCaseAndSendEvent(type, eventName, userToken, queryID, objectIdsOrFilters = ['-'], positions = [1], index = 'products') {
    //   console.log('connect algolia', type, eventName, userToken, queryID, objectIdsOrFilters, positions, index)
    //   switch (eventName) {
    //     case EventNameInsightsAlgoliaEnum.PRODUCTSBARAFTERSEARCH:
    //     case EventNameInsightsAlgoliaEnum.PRODUCTSAFTERSEARCH:
    //     case EventNameInsightsAlgoliaEnum.BRANDSAFTERSEARCH:
    //     case EventNameInsightsAlgoliaEnum.ADDCARTAFTERSEARCH:
    //       if (isValid(queryID)) {
    //         sendEvent(index, type, eventName, userToken, queryID, objectIdsOrFilters, positions)
    //         console.log('send event algolia')
    //       }
    //       break
    //     case EventNameInsightsAlgoliaEnum.PRODUCTS:
    //     case EventNameInsightsAlgoliaEnum.PRODUCTADDEDTOCART:
    //       sendEvent(index, type, eventName, userToken, null, objectIdsOrFilters, null)
    //       console.log('send event algolia')
    //       break
    //     default:
    //       break
    //   }
    // },
  },
  calculatePositionAlgolia(positionOnPage = 0, page = 0, pageSize = 0) {
    // positions $positionOnPage + $page * hitsPerPage
    return positionOnPage + page * pageSize
  },
  // test(type, queryID, objectIds) {
  //   console.log('connect',type, queryID, objectIds, [1])
  //   sendEvent('products', type, 'Products after Search', 'guest', queryID, objectIds, [1])
  // }
}
