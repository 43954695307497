export default function ({
  isHMR,
  app,
  store,
  route,
  params,
  error,
  redirect,
  // res,
}) {
  // res.setHeader('Cache-Control', 's-maxage=10000, stale-while-revalidate')

  const defaultLocale = app.i18n.fallbackLocale
  // If middleware is called from hot module replacement, ignore it
  console.log('defaultLocale', defaultLocale)
  if (isHMR) {
    return
  }
  // Get locale from params
  // console.log('params.lang', params.lang)
  const locale = params.lang || defaultLocale
  console.log('locale', locale)
  if (!store.state.locales.includes(locale)) {
    return error({ message: 'This page could not be found.', statusCode: 404 })
  }
  // Set locale
  store.commit('SET_LANG', locale)
  // console.log('SET_LANG', locale)
  app.i18n.locale = store.state.locale
  // console.log('SET_LANG18', app.i18n.locale)
  // If route is /<defaultLocale>/... -> redirect to /...
  // if (
  //   locale === defaultLocale &&
  //   route.fullPath.indexOf('/' + defaultLocale) === 0
  // ) {
  //   const toReplace =
  //     '^/' +
  //     defaultLocale +
  //     (route.fullPath.indexOf('/' + defaultLocale + '/') === 0 ? '/' : '')
  //   const re = new RegExp(toReplace)
  //   return redirect(route.fullPath.replace(re, '/'))
  // }
  // console.log('AAAAAAAa', route.fullPath.indexOf('/' + defaultLocale))
  // if (
  //   locale === defaultLocale &&
  //   route.fullPath.indexOf('/' + defaultLocale) === 0
  // ) {
  //   console.log('start if')
  //   const toReplace =
  //     '^/' +
  //     defaultLocale +
  //     (route.fullPath.indexOf('/' + defaultLocale + '/') === 0 ? '/' : '')
  //   console.log(route.fullPath.indexOf('/' + defaultLocale + '/'))
  //   console.log('route.fullPath', route.fullPath)
  //   const re = new RegExp(toReplace)
  //   console.log('toReplace', toReplace)
  //   console.log('re', re)
  //   console.log('xxxx', re, 'vvvvv', route.fullPath.replace(re, '/'))
  //   console.log((route.fullPath.replace(re, '/')))
  //   return redirect(route.fullPath.replace(re, '/'))
  // }
}
