import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from 'vee-validate/dist/locale/en'
import th from 'vee-validate/dist/locale/th'
import { localize } from 'vee-validate'
Vue.use(VueI18n)

export default ({ app, store }) => {
  localize('en', en)
  localize('th', th)
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'th',
    messages: {
      en: require('~/locales/en.json'),
      th: require('~/locales/th.json'),
    },
  })
  // Localizing the app when user refresh or access a localized link
  localize(app.i18n.locale)

  app.i18n.path = (link) => {
    // localize(app.i18n.locale)
    if (app.i18n.locale === app.i18n.fallbackLocale) {
      return `/${link}`
    }
    return `/${app.i18n.locale}/${link}`
  }
}
