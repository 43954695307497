import * as core from '@/plugins/service/core'

export const authenProvider = {
  async signIn(payload) {
    const data = core.create(`/api/v1/authen/signin`, payload)
    return data
  },
  async verify(open_id, code) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Verifies`,
      JSON.stringify({ code: code, open_id: open_id })
    )
    return data
  },
  async signInBasic(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Authentications/token/basic`,
      payload
    )
    return data
  },
  async signInOauth(payload) {
    return await core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Authentications/token/oauth`,
      payload
    )
  },
  // async signInFacebook(payload) {
  //   const data = core.create(
  //     `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Authentications/token/oauth`,
  //     payload
  //   )
  //   return data
  // },
  async registerByOauth(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Authentications/token/oauth`,
      payload
    )
    return data
  },
  async requestOneTimePassword(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/OneTimePasswords`,
      payload
    )
    return data
  },
  async requestOneTimePasswordVerify(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/OneTimePasswords/verify`,
      payload
    )
    return data
  },
  async forgotPassword(email) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Credentials/${email}/password`,
      JSON.stringify({})
    )
    return data
  },
  async verifyOTP(open_id, otpCode) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Credentials/verifyOTP?open_id=${open_id}&otp_code=${otpCode}`,
      JSON.stringify({})
    )
    return data
  },
  async updatePassword(payload) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Credentials/password`,
      payload
    )
    return data
  },
  async requestOTPByChannel(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v2/OneTimePasswords`,
      payload
    )
    return data
  },
  async verifyOTPByChannel(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v2/OneTimePasswords/verify`,
      payload
    )
    return data
  },
  async getOTPByChannel(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/OneTimePasswords/channels`,
      params
    )
    return data
  },
  async createQrCode(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/qr_codes`,
      payload
    )
    return data
  },
  async checkQrSessionStatusPollingAsync(
    qr_id,
    continue_polling_key = null,
    cancelToken = null
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/qr_codes/${qr_id}?continue_polling_key=${continue_polling_key}`,
      JSON.stringify({}),
      { cancelToken: cancelToken },
      false
    )
    return data
  },
  async verifyQrCode(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_AUTHENTICATION}api/v1/Authentications/token/qrverify`,
      payload
    )
    return data
  },
}
export default (context, inject) => {
  inject('authenProvider', authenProvider)
}
