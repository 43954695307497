import * as core from '@/plugins/service/core'

export const productNotifyMeProvider = {
  async sendNotifyMe(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/ProductNotifyMe`,
      payload
    )
    return data
  },
  async getNotification (params){
    const data = core.fetch(
      `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}api/v2/Me/Notifications`,
      params,null,false
    )
    return data
  },
  async getNotificationCount (params){
    const data = core.fetch(
      `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}api/v2/Me/Notifications/count`,
      params,null,false
    )
    return data
  },
  async updateStatusNotification(id, status) {
    const data = core.update(
      `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}api/v2/Notifications/${id}/${status}`
    )
    return data
  },
  async deleteNotification(id){
    const data = core.remove(
      `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}api/v2/Notifications/${id}`
    )
    return data
  },
  async markReadAllNoti(params){
    const data = core.update(
      `${process.env.NUXT_ENV_BASE_NOTIFICATION_URL}api/v2/Me/Notifications/MarkReadAll`,
      params
    )
    return data
  }
}
export default (context, inject) => {
  inject('productNotifyMeProvider', productNotifyMeProvider)
}
