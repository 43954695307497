export default ({ $axios, app, store, redirect }, inject) => {
  // Create an Axios instance with the base URL and necessary headers
  const api = $axios.create({
    baseURL: process.env.NUXT_ENV_OVERLAY_URL,
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${process.env.NUXT_ENV_OVERLAY_KEY}`,
      },
      post: {
        'Content-Type': 'application/json',
      },
    },
  })

  const graphqlProvider = {
    async onGetOverlay(payload) {
      // let pid = ['0001430', '0325530', '4003333']
      let pid = payload

      // const query = `
      //   query Product_overlays_product_skus {
      //     product_overlays_product_skus(
      //       filter: { product_skus_sku_code: { _in: ${JSON.stringify(pid)} } }
      //       sort: "-product_overlays_id.date_updated"
      //     ) {
      //       product_skus_sku_code
      //       product_overlays_id(
      //         filter: { channels: { _contains: "ofm" } }
      //       ) {
      //         overlay_web_image {
      //           id
      //           filename_download
      //         }
      //         date_created
      //         date_updated
      //       }
      //     }
      //   }
      // `
      const query = `
     query Product_overlays_product_skus {
    product_overlays_product_skus(
        sort: "-product_overlays_id.date_updated"
        filter: {
            product_overlays_id: {
                active_date: { _lt: "$NOW" }
                end_date: { _gte: "$NOW" }
                channels: { _contains: "ofm" }
            }
            product_skus_sku_code: { sku_code: { _in: ${JSON.stringify(pid)} } }
        }
    ) {
        id
        product_overlays_id {
            overlay_web_image {
                id
                filename_download
            }
            date_created
            date_updated
        }
        product_skus_sku_code {
            sku_code
        }
    }
}
      `
      const encodedQuery = encodeURIComponent(query)
      const response = await api
        .get(`graphql?query=${encodedQuery}`)
        .catch((error) => error.response)
      console.log(response)
      if (response)
        if (response.data?.data?.product_overlays_product_skus.length) {
          const onGetOverlay = []
          const products = response.data.data.product_overlays_product_skus

          products.forEach((e) => {
            if (
              e.product_skus_sku_code?.sku_code &&
              e.product_overlays_id?.overlay_web_image
            )
              onGetOverlay.push({
                sku_code: e.product_skus_sku_code?.sku_code,
                overlay_web_image: `${process.env.NUXT_ENV_OVERLAY_ASSETS}/${e.product_overlays_id.overlay_web_image.id}/shark_product_overlay_${e.product_overlays_id.overlay_web_image.filename_download}?format=webp`,
              })
          })
          return onGetOverlay
          // const uniqueProducts = products.reduce((acc, product) => {
          //   const sku_code = product.product_skus_sku_code

          //   if (
          //     product.product_overlays_id &&
          //     !acc.some((item) => item.sku_code === sku_code)
          //   ) {
          //     acc.push({
          //       sku_code: sku_code,
          //       overlay_web_image: `${process.env.NUXT_ENV_OVERLAY_ASSETS}/${product.product_overlays_id.overlay_web_image.id}/shark_product_overlay_${product.product_overlays_id.overlay_web_image.filename_download}?format=webp`,
          //     })
          //   }

          //   return acc
          // }, [])

          // return uniqueProducts
        }
      return false
    },
  }

  inject('graphqlProvider', graphqlProvider)
  // inject('directusImg', directusImg)
  // inject('directusSrcSet', directusSrcSet)
}
