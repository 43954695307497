let request = null
let application = null
// let locale = null
// let saleMethodId = null
export default ({ $httpClient, app }) => {
  request = $httpClient
  application = app
  // locale = application.i18n?.locale || 'th'
  // saleMethodId = app.store.state.user.profileType === 'eprocurement' ? 89 : 6
}

export function head(endpoint, data, config, showLoading = false) {
  // request.setHeader('user-locale', locale)
  const params = {
    params: data,
  }
  const newParams = Object.assign(params, config)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.head(endpoint, newParams)
  return result
}

export function fetch(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  // request.setHeader('sale-method-id', saleMethodId)
  const params = {
    params: data,
  }
  const newParams = Object.assign(params, config)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.get(endpoint, newParams)
  return result
}

export function fetchCache(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  const params = {
    params: data,
    headers: {
      'Cache-Control': 'max-age=86400',
      Age: '3600',
    },
  }
  const newParams = Object.assign(params, config)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.get(endpoint, newParams)
  return result
}

export function create(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  // console.log('request', request)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.post(endpoint, data, config)
  return result
}

export function update(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.put(endpoint, data, config)
  return result
}

export function edit(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.patch(endpoint, data, config)
  return result
}

export function remove(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  const params = { data }
  const newParams = Object.assign(params, config)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.delete(endpoint, newParams)
  return result
}

export function removeById(endpoint, id, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  const params = {
    params: {
      id,
    },
  }
  const newParams = Object.assign(params, config)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.delete(endpoint, newParams)
  return result
}

export function upload(endpoint, form, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.post(endpoint, form, config)
  return result
}

export function removeByBody(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.delete(endpoint, data, config)
  return result
}

export function patch(endpoint, data, config, showLoading = true) {
  // request.setHeader('user-locale', locale)
  if (showLoading) application.$bus.$emit('changeLoadingState', true)
  const result = request.patch(endpoint, data, config)
  return result
}
