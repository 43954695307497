// import Vue from 'vue'
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// import 'swiper/swiper-bundle.css'

// Vue.use(VueAwesomeSwiper /* , { default options with global component } */)
import Vue from 'vue'
import {
  Swiper as SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  // EffectFade,
} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)

// import style
import 'swiper/swiper-bundle.min.css'
