export const utils = {
  cryptoJS(text, textSecond) {
    let CryptoJS = require('crypto-js')

    let iv = CryptoJS.enc.Hex.parse('6d796e616d657370617373656e676572')
    let Pass = CryptoJS.enc.Utf8.parse('Crypto')
    let Salt = CryptoJS.enc.Utf8.parse('happyfishsaucetbemewhiteboard')
    let PassRound = CryptoJS.enc.Utf8.parse('CryptoRound')
    let SaltRound = CryptoJS.enc.Utf8.parse('orderunderinoptionshomegood')
    let PassTop = CryptoJS.enc.Utf8.parse('CryptoTop')
    let SaltTop = CryptoJS.enc.Utf8.parse('seaintablewinterandeyeunderear')

    let cipherText = this.encrypt(text, CryptoJS, iv, Pass, Salt)
    let cipherRoundText = this.encrypt(
      cipherText.data,
      CryptoJS,
      iv,
      PassRound,
      SaltRound
    )
    let cipherTopText = this.encrypt(
      cipherRoundText.data,
      CryptoJS,
      iv,
      PassTop,
      SaltTop
    )
    return cipherTopText.data
    // let cipherSpecialRoundText = this.encrypt(
    //   textSecond,
    //   CryptoJS,
    //   iv,
    //   PassRound,
    //   SaltRound
    // )
    // let cipherSpecialTopText = this.encrypt(
    //   cipherSpecialRoundText.data,
    //   CryptoJS,
    //   iv,
    //   PassTop,
    //   SaltTop
    // )
    // let cipherResult = this.encrypt(
    //   cipherSpecialTopText.data,
    //   CryptoJS,
    //   iv,
    //   Pass,
    //   Salt
    // )

    // let cipherTopTextLength = `${cipherTopText.sigBytes}`
    // let cipherResultLength = `${cipherResult.sigBytes}`
    // let result = `${cipherTopText.data}${cipherResult.data}`

    // let position =
    //   result.length + cipherTopTextLength.length + cipherResultLength.length + 2
    // let secret = `${cipherTopTextLength.length}${cipherTopText.sigBytes}${cipherResultLength.length}${cipherResult.sigBytes}`
    // let caluclatePos = position / 2
    // return `${result.slice(0, caluclatePos)}${secret}${result.slice(
    //   caluclatePos
    // )}`
  },
  encrypt(text, CryptoJS, iv, Pass, Salt) {
    let key128Bits1000Iterations = CryptoJS.PBKDF2(
      Pass.toString(CryptoJS.enc.Utf8),
      Salt,
      { keySize: 128 / 32, iterations: 1000 }
    )

    let encrypted = CryptoJS.AES.encrypt(text, key128Bits1000Iterations, {
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    })
    console.log(encrypted)
    return {
      data: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
      sigBytes: encrypted.ciphertext.sigBytes,
    }
  },
  isValid(data) {
    return data !== '' && data !== undefined && data !== null ? true : false
  },
  isObjectValid(data) {
    return data !== undefined && data !== null ? true : false
  },
  containsKey(obj, key) {
    return Object.keys(obj).includes(key)
  },
  isObjectKey(obj) {
    return Object.keys(obj).length > 0
  },
  isObjectKeyValid(data, key) {
    return (
      data !== undefined && data !== null && Object.keys(data).includes(key)
    )
  },
  anyArrayObjectValid(data) {
    return data !== undefined && data !== null && data.length > 0
  },
  formatPrice(value, fixed, onlyDecimal = false) {
    let val = (value / 1).toFixed(fixed).replace(',', '.')
    return onlyDecimal
      ? val
      : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatDateExcludeTime(
    date,
    inputFormat = 'YYYY/MM/DD HH:mm:ss ZZ',
    outputFormat = 'DD/MM/YYYY'
  ) {
    let moment = require('moment')
    return moment(date, inputFormat).format(outputFormat)
  },
  formatDateTime(date) {
    let moment = require('moment')
    return moment(date, 'YYYY/MM/DD HH:mm:ss ZZ').format('DD/MM/YYYY HH:mm')
  },
  formatDateGTM(date, inputFormat = 'YYYY/MM/DD HH:mm:ss ZZ') {
    let moment = require('moment')
    return moment(date, inputFormat).format('DD/MM/YY')
  },
  getDate() {
    let moment = require('moment')
    return moment().format('DD/MM/YYYY')
  },
  getDateString() {
    let moment = require('moment')
    return moment().format('YYYYMMDD')
  },
  isExpireDate(date) {
    let moment = require('moment')
    let currentDate = moment().startOf('miniute').toDate()
    let expireDate = moment(date, 'YYYY/MM/DD HH:mm:ss ZZ')
      .startOf('miniute')
      .toDate()
    return currentDate > expireDate
  },
  ToISOString(date) {
    return new Date(date).toString() !== 'Invalid Date'
      ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString(
          'en-GB'
        )
      : undefined
  },
  convertDatetoTicks(date, type) {
    let moment = require('moment')
    if (date === null || date === '') return null
    const time = type === 'from' ? ' 00:00:00' : ' 23:59:59'
    const dateFormat = moment(date + time, 'DD/MM/YYYY HH:mm:ss')
    return (
      dateFormat._d * 10000 +
      621355968000000000 -
      dateFormat._d.getTimezoneOffset() * 600000000
    )
  },
  isPasswordExpire(date) {
    let moment = require('moment')
    let currentDate = moment().startOf('hour').toDate()
    let expireDate = moment(date, 'YYYY/MM/DD HH:mm:ss ZZ')
      .startOf('hour')
      .toDate()
    console.log('currentDate', currentDate)
    console.log('expireDate', expireDate)
    return currentDate > expireDate
  },
  async importExcel(rawFile) {
    console.log('rawFile', rawFile)
    // console.log('rABS', rABS)
    // let XLSX = require('xlsx')
    const XLSX = await import('xlsx')
    const result = await this.fileConvertToWorkbook(rawFile)
      .then((workbook) => {
        let xlsxArr

        xlsxArr = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]]
        )
        console.log('xlsxArr', xlsxArr)
        // return xlsxArr
        // this.workbook = workbook

        return this.initTable(this.xlsxArrToTableArr(xlsxArr))
      })
      .catch((err) => {
        console.error(err)
      })
    console.log('result', result)
    return result
  },
  async fileConvertToWorkbook(file) {
    // let XLSX = require('xlsx')
    const XLSX = await import('xlsx')
    let reader = new FileReader()
    let fixdata = (data) => {
      let o = ''
      let l = 0
      let w = 10240
      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        )
      }
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
      return o
    }
    return new Promise((resolve, reject) => {
      try {
        reader.onload = (renderEvent) => {
          let data = renderEvent.target.result
          let arr = fixdata(data)
          resolve(XLSX.read(btoa(arr), { type: 'base64' }))
          // if (rABS) {
          //   /* if binary string, read with type 'binary' */
          //   resolve(XLSX.read(data, { type: 'binary' }))
          // } else {
          //   /* if array buffer, convert to base64 */
          //   let arr = fixdata(data)
          //   resolve(XLSX.read(btoa(arr), { type: 'base64' }))
          // }
        }
        reader.onerror = (error) => {
          reject(error)
        }
        // if (rABS) {
        //   reader.readAsBinaryString(file)
        // } else {
        //   reader.readAsArrayBuffer(file)
        // }
        reader.readAsArrayBuffer(file)
      } catch (error) {
        reject(error)
      }
    })
  },
  xlsxArrToTableArr(xlsxArr) {
    let tableArr = []
    let length = 0
    let maxLength = 0
    let maxLengthIndex = 0
    xlsxArr.forEach((item, index) => {
      length = Object.keys(item).length
      if (maxLength < length) {
        maxLength = length
        maxLengthIndex = index
      }
    })
    let tableHeader = Object.keys(xlsxArr[maxLengthIndex])
    let tableHeaderLowerCase = Object.keys(xlsxArr[maxLengthIndex])
    tableHeaderLowerCase = tableHeaderLowerCase.map((item) => {
      // console.log('item', item)
      return item.replace(/[' ]/g, '').toLowerCase()
    })
    let rowItem = {}
    xlsxArr.forEach((item) => {
      rowItem = {}
      for (let i = 0; i < maxLength; i++) {
        rowItem[tableHeaderLowerCase[i]] = item[tableHeader[i]] || ''
      }
      tableArr.push(rowItem)
    })
    return {
      header: tableHeader,
      data: tableArr,
    }
  },
  tableArrToXlsxArr({ data, header }) {
    let xlsxArr = []
    let tempObj = {}
    data.forEach((rowItem) => {
      tempObj = {}
      rowItem.forEach((item, index) => {
        tempObj[header[index]] = item
      })
      xlsxArr.push(tempObj)
    })
    return xlsxArr
  },
  initTable({ data, header }) {
    let tableData = {
      header: [],
      body: [],
    }
    tableData.header = header
    tableData.body = data
    return tableData
  },
  get2c2pPaymentExpire() {
    let moment = require('moment')
    const today = new Date()
    const expire = new Date(today)
    expire.setDate(expire.getDate() + 2)
    // console.log(expire)
    let expireDate = moment(expire).format('yyyy-MM-DD HH:mm:ss')
    return expireDate
  },
  async importCSV(file) {
    const reader = new FileReader()
    reader.readAsText(file)
    return new Promise((resolve, reject) => {
      try {
        reader.onload = async () => {
          let str = reader.result
          const headers = str.slice(0, str.indexOf('\r\n')).split(',')
          const rows = str.slice(str.indexOf('\n') + 1).split('\r\n')
          const arr = rows.map(function (row) {
            const values = row.split(',')
            const el = {}
            headers.forEach((fieldName, index) => {
              el[fieldName.toLowerCase()] = values[index]
            })
            return el
          })
          resolve(arr)
        }
        reader.onerror = (error) => {
          reject(error)
        }
      } catch (error) {
        reject(error)
      }
    })
  },
  displayOrderStatus(orderDocument) {
    let orderStatusName = ''
    let orderStatusName2 = ''
    if (orderDocument.status === 'Waiting') {
      if (
        this.isValid(orderDocument.approval_status) &&
        orderDocument.approval_status !== 'None' &&
        orderDocument.approval_status !== 'Approve'
      ) {
        if (orderDocument.approval_status === 'Waiting')
          orderStatusName = 'order_status.WaitingApproval'
        else orderStatusName = `order_status.${orderDocument.approval_status}`
        if (orderDocument.payment_status === 'Waiting')
          orderStatusName2 = `order_status.WaitingPayment`
      } else {
        if (orderDocument.payment_status === 'Waiting')
          orderStatusName = `order_status.WaitingPayment`
      }
    } else orderStatusName = `order_status.${orderDocument.status}`
    console.log('orderStatusName', orderStatusName)
    return [orderStatusName, orderStatusName2]
  },
  imageTimeStamp(src, update_date, useDefault, isPreload) {
    let img = src // item.product_image_url
    let errImg = useDefault
      ? 'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      : ''
    // let timeStamp = update_date ? '?v=' + this.getDateString(update_date) : ''
    let webp = 'x-image-process=image/format,webp' // set default webp for preload
    let queryString = ''

    if (update_date) {
      let timeStamp = this.getDateString(update_date)
      queryString = isPreload ? `?v=${timeStamp}&${webp}` : `?v=${timeStamp}`
    } else {
      queryString = isPreload ? `?${webp}` : ''
    }

    return this.isValid(img) ? img + queryString : errImg
  },
  isExpireDateLimit(flash_sale_expire_date) {
    let expire_flash_sale = new Date(flash_sale_expire_date).getTime()
    console.log('isExpireDateLimit', expire_flash_sale)
    return expire_flash_sale > -62135596800000
  },
  generateStyle(obj, width) {
    let style = ''
    if (obj.size) style += 'font-size:' + obj.size + 'px;'

    if (obj.position_left > 0) style += 'left:' + obj.position_left + 'px;'
    if (obj.position_top > 0) style += 'top:' + obj.position_top + 'px;'
    if (obj.position_right > 0) style += 'right:' + obj.position_right + 'px;'

    if (obj.align) style += 'text-align:' + obj.align + ';'
    if (obj.color) style += 'color:' + obj.color + ';'
    if (obj.family) style += 'font-family:' + obj.family + ';'
    if (obj.bold) style += 'font-weight:bold;'
    if (obj.underline) style += 'text-decoration:underline;'
    if (obj.italic) style += 'font-style: italic;'
    style += 'width:' + width + 'px'
    return style
  },
  genImgSrcSet(pathName, options = [{ w: 150, h: 150, q: 90, ws: '150w' }]) {
    let srcsets = []
    if (options.length > 0) {
      for (const element of options) {
        let pathtemp = pathName
        let query = pathtemp.includes('?v=') ? `&` : `?`
        const option = element

        pathtemp = `${pathName}${query}${this.genXimage(
          option.w,
          option.h,
          option.q
        )} ${option.ws}`

        srcsets = [...srcsets, pathtemp]
      }
      // return srcsets.join(',')
    }

    return srcsets.length ? srcsets.join(',') : `${pathName}`
  },
  genXimage(w, h, q) {
    return `x-image-process=image/format,webp/resize,m_fixed,w_${w},h_${h}/quality,q_${q}/marker,u_plus/sharpen,50`
  },
  genImgSrcSetMpad(
    pathName,
    options = [{ w: 150, h: 150, q: 90, ws: '150w' }]
  ) {
    let srcsets = []

    if (options.length > 0) {
      for (const element of options) {
        const option = element
        srcsets = [
          ...srcsets,
          `${pathName}${this.genXimageMpad(option.w, option.h, option.q)} ${
            option.ws
          }`,
        ]
      }
      // console.log('srcsets', srcsets)
      // return srcsets.join(',')
    }

    return srcsets.length ? srcsets.join(',') : `${pathName}`
  },
  genXimageMpad(w, h, q) {
    return `?x-image-process=image/format,webp/resize,m_pad,w_${w},h_${h}/quality,q_${q}/marker,u_plus/sharpen,50`
  },
  onPreload(imagesrcset, imagesizes) {
    // pathName,
    if (process.client)
      if (imagesrcset && imagesizes) {
        let specificPreloadLink = document.head.querySelector(
          `link[rel="preload"][imagesrcset="${imagesrcset}"]`
        )
        // if null create preload link
        if (specificPreloadLink === null) {
          let link = document.createElement('link')
          link.rel = 'preload'
          link.as = 'image'
          // link.href = pathName

          link.setAttribute('imagesrcset', imagesrcset)
          link.setAttribute('imagesizes', imagesizes)
          link.setAttribute('data-n-head', 'ssr')
          document.head.appendChild(link)
        }
      }
  },
  // onPreloadByImg(pathName, options = []) {
  //   if (options.length > 0) {
  //     for (const element of options) {
  //       let pathtemp = pathName
  //       let query = pathtemp.includes('?v=') ? `&` : `?`
  //       const option = element

  //       pathtemp = `${pathName}${query}${this.genXimage(
  //         option.w,
  //         option.h,
  //         option.q
  //       )}`
  //       let specificPreloadLink = document.head.querySelector(
  //         `link[rel="preload"][href="${pathtemp}"]`
  //       )
  //       // if null create preload link
  //       if (specificPreloadLink === null) {
  //         let link = document.createElement('link')
  //         link.rel = 'preload'
  //         link.as = 'image'
  //         link.href = pathtemp

  //         // link.setAttribute('media', option.media)
  //         document.head.appendChild(link)
  //       }
  //     }
  //     // return srcsets.join(',')
  //   }
  // },
  formatDateText(value, lang, num) {
    if (!value) return ''
    let moment = require('moment')
    let date = moment(value, 'YYYY/MM/DD HH:mm:ss ZZ').format(
      'DD/MM/YYYY HH:mm'
    )
    if (date === '01/01/0001 00:00' || date === '01/01/1900 00:00') return '-'
    let day = moment(value).format('DD')
    let month = moment(value).format('MM')
    let year = moment(value).format('YYYY')
    let monthNames =
      lang === 'th'
        ? [
            'ม.ค.',
            'ก.พ.',
            'มี.ค.',
            'เม.ย.',
            'พ.ค.',
            'มิ.ย.',
            'ก.ค.',
            'ส.ค.',
            'ก.ย.',
            'ต.ค.',
            'พ.ย.',
            'ธ.ค.',
          ]
        : [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]
    // console.log(lang)
    let bc = lang === 'th' ? Number(year) + 543 : year
    if (num) {
      date = `${day} ${monthNames[month - 1]} ${bc}`
    } else {
      date = `${day} ${monthNames[month - 1]} ${bc.toString().slice(-2)}`
    }

    return date
  },
  productCategories(categories) {
    let item_categories = {
      item_category:
        categories.find((item) => item.level === 1)?.display_name?.en || '',
      item_category2:
        categories.find((item) => item.level === 2)?.display_name?.en || '',
      item_category3:
        categories.find((item) => item.level === 3)?.display_name?.en || '',
    }

    return item_categories
  },
  // insertBefore(element, elementClass, inner) {
  //   if (element && process.client) {
  //     const target = document.querySelector(element)
  //     if (target) {
  //       const newElement = document.createElement('div')
  //       newElement.innerText = inner
  //       newElement.className = elementClass
  //       target.parentNode.insertBefore(newElement, target)
  //     }
  //   }
  // },
  // removeElement(element) {
  //   if (element && process.client) {
  //     const elements = document.querySelectorAll(element)
  //     elements.forEach((el) => {
  //       el.parentNode.removeChild(el)
  //     })
  //   }
  // },
}

export function debounce(fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    let args = arguments
    let that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export default (ctx, inject) => {
  const pageInfo = (page) => {
    if (ctx.$gtm) {
      ctx.$gtm.push({
        event: 'pageInfo',
        page: {
          type: page,
          path: ctx.route.path,
          title: ctx.app.head.title,
        },
        session: {
          isLoggedIn: ctx.store.getters['authen/isAuthenticated']
            ? 'Yes'
            : 'No',
        },
        user: ctx.store.getters['user/userInfoTrack'],
      })
    }
  }

  inject('utils', utils)
  inject('pageInfo', pageInfo)
}
