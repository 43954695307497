const middleware = {}

middleware['authentication'] = require('../middleware/authentication.js')
middleware['authentication'] = middleware['authentication'].default || middleware['authentication']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['sf-script'] = require('../middleware/sf-script.js')
middleware['sf-script'] = middleware['sf-script'].default || middleware['sf-script']

export default middleware
