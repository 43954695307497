import * as core from '@/plugins/service/core'

const bizCardProvider = {
  //paper price
  async getPrintingPrices(query) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/companies/paper-prices`,
      query
    )
    return data
  },

  // categories
  async getCategories(query) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/companies/categories`,
      query
    )
    return data
  },
  async getCategoriesList(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/companies/${company_id}/categories/names`,
      company_id
    )
    return data
  },

  // template

  async getCompaniesTemplates(query) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/companies/templates`,
      query
    )
    return data
  },
  async getCompaniesTemplatesById(company_id, template_id) {
    const data = await core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/companies/${company_id}/templates/${template_id}`
    )
    return data
  },

  // orders
  async searchOrders(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders`,
      params
    )
    return data
  },
  async createOrder(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders`,
      payload
    )
    return data
  },

  async getOrderDocument(order_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/${order_id}`,
      params
    )
    return data
  },
  async getOrderActivityLogs(order_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/activity-log/${order_id}`,
      params,
      null,
      false
    )
    return data
  },
  async approveOrders(body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/approval/information`,
      body
    )
    return data
  },
  async approveOrderFromMail(otp_prove_token, body) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'put',
      data: body,
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v2/Orders/approval/information/form_email`,
    })
    return data
  },
  updateOrderPODetail(orderId, body, config) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/${orderId}/po/detail`,
      body,
      config,
      false
    )
    return data
  },
  // cart
  async getCartItems(key, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${key}/items`,
      params
    )
    return data
  },
  async getCartCount(key, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${key}/count`,
      params
    )
    return data
  },
  async addShoppingCarts(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart`,
      payload
    )
    return data
  },
  async editOrderItem(key, payload) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${key}/items`,
      payload
    )
    return data
  },
  async getRequesterLineByUser(company_id, openId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/profile/${company_id}/user/${openId}`
    )
    return data
  },
  async getShippingByCoscenter(key, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${key}/detail`,
      params
    )
    return data
  },
  async getCalculateCart(key, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${key}/calculate`,
      params
    )
    return data
  },
  async getCompaniesBudgetPrintingControl(
    company_id,
    params,
    showLoading = true
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/shoppingCart/${company_id}/budgets`,
      params,
      null,
      showLoading
    )
    return data
  },
  async uploadPOFile(apiData, config, showLoading = true) {
    const data = core.upload(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/files`,
      apiData,
      config,
      showLoading
    )
    return data
  },
  // reports
  async getOrderReportPrinting(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/reports/orders`,
      params
    )
    return data
  },
  async getReportBudgets(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/reports/budgets`,
      params
    )
    return data
  },
  async getReportBudgetsLog(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/reports/budgetlogsreport`,
      params
    )
    return data
  },
  // approve from email
  async getOrderDocumentFromEmailNew(
    order_id,
    verifyKey,
    isExportPurchasePdf = false,
    otp_prove_token,
    otp_channel
  ) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'Get',
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v2/orders/${order_id}/from_email?verify_key=${verifyKey}&is_export_purchase_pdf=${isExportPurchasePdf}&is_otp_proved
=${otp_channel !== "QRCode" ? true:false}`,
    })
    // const data = core.fetch(
    //   `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/${order_id}/from_email`,
    //   params
    // )
    return data
  },
  async getOrderDocumentFromEmail(order_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v1/orders/${order_id}/from_email`,
      params
    )
    return data
  },
  async getVerifyOrderStatusFromEmail(verifyKey, body) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_BIZCARD}api/v2/Orders/verifyorderstatus?verify_key=${verifyKey}`,
      body
    )
    return data
  },
}

// export default bizCardProvider
export default (context, inject) => {
  inject('bizCardProvider', bizCardProvider)
}
