import jwtDecode from 'jwt-decode'
const Cookie = process.client ? require('js-cookie') : undefined

export const state = () => ({
  accessToken: '',
  refreshToken: '',
  openId: '',
  guestId: '',
  isAuthenticated: false,
  user: {},
  routeApproveRedirect: '',
})

export const mutations = {
  SET_AUTH_TOKEN(state, data) {
    if (data.accessToken !== undefined) {
      state.accessToken = data.accessToken
      // state.refreshToken = data.refreshToken
      state.openId = data.openId
      // state.user = data.user
    }
  },
  IS_GUESTID(state) {
    if (Cookie !== undefined) {
      if (!Cookie.get('openId')) {
        if (!Cookie.get('guestId')) {
          const random = 'guest_' + Math.random().toString(36).substring(3)
          Cookie.set('guestId', random, {
            expires: 1,
            sameSite: 'none',
            secure: true,
          })
        }
      } else {
        Cookie.remove('guestId')
      }
      state.guestId = Cookie.get('guestId') || ''
    }
  },
  IS_AUTHENTICATED(state) {
    if (
      state.accessToken !== '' &&
      state.accessToken !== undefined &&
      state.accessToken !== null
    ) {
      const decoded = jwtDecode(state.accessToken)
      const exp = decoded.exp
      // console.log('exp', exp - Date.now() / 1000 < 30)
      if (exp - Date.now() / 1000 < 30) {
        state.isAuthenticated = false
      } else {
        state.isAuthenticated = true
      }
    } else state.isAuthenticated = false
  },
  IS_AUTHENTICATED_BY_COOKIE(state, token) {
    if (token !== '' && token !== undefined && token !== null) {
      const decoded = jwtDecode(token)
      const exp = decoded.exp
      if (exp - Date.now() / 1000 < 30) {
        state.isAuthenticated = false
      } else {
        state.isAuthenticated = true
      }
    } else state.isAuthenticated = false
  },
  SIGNOUT(state) {
    // Cookie.remove('refreshToken')
    if (process.client)
      if (Cookie !== undefined) {
        Cookie.remove('accessToken')
        Cookie.remove('openId')
        // Cookie.remove('secretKey')
        Cookie.remove('user')
        Cookie.remove('cartId')
        // Cookie.remove('coupons')
        Cookie.remove('userProfile')
        Cookie.remove('userInfo')
      }
    // state.refreshToken = ''
    state.accessToken = process.env.NUXT_ENV_GUEST_TOKEN
    state.isAuthenticated = false
    state.user = {}
    state.openId = ''
  },
  LOAD_USER(state, user) {
    if (user) state.user = JSON.parse(user)
  },
  SAVE_APPROVE_REDIRECT(state, path) {
    state.routeApproveRedirect = path
  },
}

export const actions = {
  async setAuthToken({ dispatch, commit }, payload) {
    dispatch('cart/setCartCountPrinting', 0, { root: true })
    commit('SET_AUTH_TOKEN', payload)
    if (Cookie !== undefined && payload.accessToken !== undefined) {
      // Cookie.set('refreshToken', payload.refreshToken, {
      //   expires: 1,
      //   sameSite: 'none',
      //   secure: true,
      // })
      Cookie.set('accessToken', payload.accessToken, {
        expires: 1,
        sameSite: 'none',
        secure: true,
      })
      Cookie.set('openId', payload.openId, {
        expires: 1,
        sameSite: 'none',
        secure: true,
      })
    }
  },
  isAuthenticated({ commit }) {
    commit('IS_AUTHENTICATED')
  },
  isAuthenticatedByCookie({ commit }, payload) {
    commit('IS_AUTHENTICATED_BY_COOKIE', payload)
  },
  signout({ dispatch, commit }) {
    dispatch('user/removeUserProfile', 0, { root: true })
    dispatch('cart/setCartCountPrinting', 0, { root: true })
    commit('SIGNOUT')
  },
  isGuestId({ commit }) {
    commit('IS_GUESTID')
  },
  saveApproveRedirect({ commit }, path) {
    commit('SAVE_APPROVE_REDIRECT', path)
  },
}

export const getters = {
  accessToken: (state) =>
    state.accessToken !== undefined &&
    state.accessToken !== null &&
    state.accessToken !== ''
      ? jwtDecode(state.accessToken)
      : '',
  refreshToken: (state) => state.refreshToken,
  openId: (state) => state.openId,
  guestId: (state) => state.guestId,
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
  isGuest: (state) => {
    // console.log('isGuest', state.accessToken)
    if (
      state.accessToken !== undefined &&
      state.accessToken !== null &&
      state.accessToken !== ''
    ) {
      const decoded = jwtDecode(state.accessToken)
      return (
        decoded !== undefined && decoded !== null && decoded.isguest === 'true'
      )
    }
    return false
  },
  routeApproveRedirect: (state) => state.routeApproveRedirect,
}
