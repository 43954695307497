export default ({ app }, inject) => {
  inject('_onClickBanner', async (banner, bannerCreative) => {
    console.log('_onClickBanner', banner)
    if (banner) {
      // !== undefined
      if (app.$utils.isValid(bannerCreative)) {
        //gtm promoClicked
        app.$gtm.push({ ecommerce: null })
        app.$gtm.push({
          event: 'promoClicked',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  id: banner.src,
                  name: `HP|${banner.gtmSeq}|${
                    banner.title
                  }|${app.$utils.formatDateGTM(
                    banner.active_from
                  )}_${app.$utils.formatDateGTM(banner.active_to)}`,
                  creative: bannerCreative,
                  position: banner.gtmSeq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const promotion = {
          creative_name: banner.title,
          creative_slot: `${banner.title}_${banner.gtmSeq}`,
          promotion_id: banner.src,
          promotion_name: `HP|${banner.gtmSeq}|${
            banner.title
          }|${app.$utils.formatDateGTM(
            banner.active_from
          )}_${app.$utils.formatDateGTM(banner.active_to)}`,
        }
        app.$gtm.push({ ecommerce: null })
        app.$gtm.push({
          event: 'select_promotion',
          ecommerce: {
            user: app.store.getters['user/userInfoTrack'],
            ...promotion,
          },
        })
        app.store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
      }
    }
  })
}
