const Cookie = process.client ? require('js-cookie') : undefined
export const state = () => ({
  displayPaymentStep: true,
  currentTransactionId: '',
  display2C2PPaymentStep: undefined,
})

export const mutations = {
  SET_DISPLAY_PAYMENT_STEP(state, payload) {
    state.displayPaymentStep = payload
  },
  SET_CURRENT_TRANSACTION_ID(state, payload) {
    state.currentTransactionId = payload
    Cookie.set('currentTransactionId', payload, {
      expires: 7,
      sameSite: 'none',
      secure: true,
    })
  },
  LOAD_CURRENT_TRANSACTION_ID(state, { transactionId }) {
    state.currentTransactionId = transactionId
  },
  SET_DISPLAY_2C2P_PAYMENT_STEP(state, payload) {
    state.display2C2PPaymentStep = payload
  },
}

export const actions = {
  setDisplayPaymentStep({ commit }, payload) {
    commit('SET_DISPLAY_PAYMENT_STEP', payload)
  },
  setCurrentTransactionId({ commit }, payload) {
    commit('SET_CURRENT_TRANSACTION_ID', payload)
  },
  setDisplay2C2PPaymentStep({ commit }, payload) {
    commit('SET_DISPLAY_2C2P_PAYMENT_STEP', payload)
  },
}

export const getters = {
  displayPaymentStep: (state) => state.displayPaymentStep,
  currentTransactionId: (state) => state.currentTransactionId,
  display2C2PPaymentStep: (state) => state.display2C2PPaymentStep,
}
