export const NotificationEnum = {
  UNREAD: "Unread",
  ACKNOWLEDGE: "Acknowledge",
  READ: "Read",
  DELETE : "Delete",
  REFECT : "Refect",
  MAKEALL : "MakeAll",
};
export const NotificationTypeEnum = {
  ROUTEPATH: "route_path"
};
export const NotificationOFMEnum = {
  SENDER_App : "www.ofm.co.th",
  SENDER_ID: "14aca071a07c",
  LINK_CDN : "https://ofm-cdn0.ofm.co.th/images/logo/ofmbiz-logo-icon.png"
};
export const NotificationSenderType = {
  anonymous_user: "anonymous_user",
  user : "user",
  application : "application"
};