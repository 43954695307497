export default ({ $axios, app, store, redirect }, inject) => {
  const api = $axios.create({
    baseURL: process.env.NUXT_ENV_SHOP_IN_SHOP_URL,
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${process.env.NUXT_ENV_SHOP_IN_SHOP_KEY}`,
      },
      post: {
        'Content-Type': 'application/json',
      },
    },
  })
  const lang = app.i18n.locale === 'th' ? 'th-TH' : 'en-US'

  async function fetchGraphQLData(query, variables) {
    try {
      const response = await api.post('graphql', {
        query,
        variables,
      })
      console.log('response', response)
      if (response.data?.data) {
        return response.data.data
      }
    } catch (e) {
      console.error('Error fetching shop data:', e)
    }
  }

  const graphQL = {
    onGraphQLImg(id) {
      return `${process.env.NUXT_ENV_SHOP_IN_SHOP_ASSETS}/${id}`
    },
    async onGetBrandsID(brands_id) {
      // let dummy = 'kidna.shop' // nongaey.official
      const query = `
        query Shops_brands($locale: String, $brands_id: String) {
          shops_brands {
              brands_id(filter: { id: { _eq: $brands_id } }) {
                  translations(filter: { languages_code: { code: { _eq: $locale } } }) {
                      display_name
                  }
              }
          }
      }
      `
      const variables = {
        locale: lang,
        brands_id: brands_id,
      }

      const data = await fetchGraphQLData(query, variables)
      return data
    },
    async onGetBrandsHighlight(type) {
      // , shop_highlight: { _eq: "0" }
      const query = `
        query Shops {
            shops(
                filter: {
                    status: { _eq: "active" }
                    
                    shop_types_id: { type: { _eq: "${type}" } }
                }
                sort: "shop_highlight"
            ) {
                id
                status
                shop_highlight
                unique_name
                translations {
                    display_name
                    description
                    meta_title_tag
                    meta_seo_name
                    meta_description_tag
                }
                logo {
                    id
                    filename_download
                    title
                    modified_on
                    description
                    tags
                    metadata
                }
                template_publish {
                    shop_id
                    config
                    publish_date
                    shops_templates_id {
                        id
                        config
                    }
                    shop_components(
                        filter: { component_masters_id: { code: { _eq: "cover_banner" } } }
                    ) {
                        id
                        display_name
                        config
                        is_fix_position
                        sequence
                        components_images {
                            id
                            component_section
                            link_url
                            link_target
                            sequence
                            web_image {
                                id
                                filename_download
                                title
                                modified_on
                                description
                                tags
                                metadata
                            }
                        }
                    }
                }
            }
        }

      `
      // const variables = {
      //   locale: lang,
      //   brands_id: brands_id,
      // }

      const data = await fetchGraphQLData(query)
      return data
    },
    async onGetBrandsAsync(
      type,
      isHighlight = false,
      pageNo = 1,
      pageSize = 10
    ) {
      const query = `
        query Shops {
            shops(
                 filter: {
                    status: { _eq: "active" }
                    shop_highlight: { ${
                      isHighlight ? `_neq: "0"` : `_eq: "0"`
                    } }
                    shop_types_id: { type: { _eq: "${type}" } }
                
                    template_publish: {shop_id: {_neq: "null" } }
                }
                sort: "shop_highlight"
                 page: ${pageNo}
                 limit: ${pageSize}
            ) {
                status
                id
                shop_highlight
                logo{
                    id}
                translations {
                    display_name
                    description
                    languages_code {
                        code
                        name
                        direction
                    }
                    meta_seo_name
                }
                unique_name
                template_publish {
                    shop_id
                    config
                    publish_date
                    shop_components(
                        filter: { component_masters_id: { code: { _eq: "cover_banner" } } }
                    ) {
                        id
                        display_name
                        config
                        is_fix_position
                        sequence
                        component_masters_id {
                            code
                        }
                        components_images {
                            id
                            component_section
                            link_url
                            link_target
                            sequence
                            web_image {
                                id
                                filename_download
                                uploaded_on
                                modified_on
                            }
                        }
                    }
                }
                sellers_id {
                        seller_id
                }
                shops_brands {
                    id
                    brands_id {
                        id
                    }
                }
                shop_tiers_id {
                    id
                    value
                }
            }
        }
  
      `
      // const query = `
      //   query Shops {
      //       shops(
      //           filter: {
      //               status: { _eq: "active" }

      //               shop_types_id: { type: { _eq: "${type}" } }
      //           }
      //           sort: "shop_highlight"
      //       ) {
      //           id
      //           status
      //           shop_highlight
      //           unique_name
      //           translations {
      //               display_name
      //               description
      //               meta_title_tag
      //               meta_seo_name
      //               meta_description_tag
      //           }
      //           logo {
      //               id
      //               filename_download
      //               title
      //               modified_on
      //               description
      //               tags
      //               metadata
      //           }
      //           template_publish {
      //               shop_id
      //               config
      //               publish_date
      //               shops_templates_id {
      //                   id
      //                   config
      //               }
      //               shop_components(
      //                   filter: { component_masters_id: { code: { _eq: "cover_banner" } } }
      //               ) {
      //                   id
      //                   display_name
      //                   config
      //                   is_fix_position
      //                   sequence
      //                   components_images {
      //                       id
      //                       component_section
      //                       link_url
      //                       link_target
      //                       sequence
      //                       web_image {
      //                           id
      //                           filename_download
      //                           title
      //                           modified_on
      //                           description
      //                           tags
      //                           metadata
      //                       }
      //                   }
      //               }
      //           }
      //       }
      //   }

      // `
      // const variables = {
      //   locale: lang,
      //   brands_id: brands_id,
      // }

      const data = await fetchGraphQLData(query)
      return data
    },
    async onGetDropShipHighlight(isHighlight = false, type) {
      const query = `
        query Shops {
    shops(
        filter: {
            status: { _eq: "active" }
            shop_highlight: {${isHighlight ? `_neq: "0"` : `_eq: "0"`} }
            shop_types_id: { type: { _eq: "${type}" } }
            template_publish: {shop_id: {_neq: "null" } }
        }
        sort: "shop_highlight"
    ) {
        status
        id
        shop_highlight
        translations {
            display_name
            description
            languages_code {
                code
                name
                direction
            }
        }
        unique_name
        template_publish {
            shop_id
            config
            publish_date
            shop_components(
                filter: { component_masters_id: { code: { _eq: "cover_banner" } } }
            ) {
                id
                display_name
                config
                is_fix_position
                sequence
                component_masters_id {
                    code
                }
                components_images {
                    id
                    component_section
                    link_url
                    link_target
                    sequence
                    web_image {
                        id
                        filename_download
                        uploaded_on
                        modified_on
                    }
                }
            }
        }
            sellers_id {
            seller_id
        }
        shop_tiers_id(filter: { id: { _gte: "30" } }) {
            id
            value
        }
    }
}
 `
      const data = await fetchGraphQLData(query)
      return data
    },
    // _or: [
    //     { sellers_id: { seller_id: { _eq: "${product_seller_id}" } } }
    //     { shops_brands: { brands_id: { id: { _eq: "${product_brand_id}" } } } }
    // ]
    async onGetShopDetail(sku_code, product_brand_id, product_seller_id, type) {
      const filterTypeSku = `
            ${
              type === 'OFMStock'
                ? `shops_brands: { brands_id: { id: { _eq: "${product_brand_id}" } } } `
                : `sellers_id: { seller_id: { _eq: "${product_seller_id}" } }`
            }
        `
      const query = `
         query ProductDetailPage {
    product_overlays_product_skus(
        filter: {
            product_overlays_id: {
                active_date: { _lt: "$NOW" }
                end_date: { _gte: "$NOW" }
                channels: { _contains: "ofm" }
            }
            product_skus_sku_code: { sku_code: { _eq: "${sku_code}" } }
        }
        sort: "-product_overlays_id.date_updated"
    ) {
        product_overlays_id {
            overlay_web_image {
                id
                filename_download
            }
            date_created
            date_updated
        }
        product_skus_sku_code {
            sku_code
        }
    }
    shops(filter: {   ${filterTypeSku} }, sort: "-shop_highlight") {
        id
        status
        shop_highlight
        logo{id}
        unique_name
        template_publish {
            config
            publish_date
            shop_components(
                filter: { component_masters_id: { code: { _eq: "cover_banner" } } }
            ) {
                id
                sequence
                component_masters_id {
                    code
                }
                components_images {
                    id
                    component_section
                    link_url
                    link_target
                    sequence
                    web_image {
                        id
                        filename_download
                    }
                    mobile_image {
                        id
                        filename_download
                        filename_disk
                    }
                    translations {
                        alt
                        languages_code {
                            code
                        }
                    }
                }
                components_texts {
                    sequence
                    content_type
                    component_section
                    translations {
                        source
                        alt
                        languages_code {
                            code
                        }
                    }
                }
            }
        }
        translations {
            display_name
            languages_code {
                code
                name
                direction
            }
        }
            shop_types_id {
            id
            type
        }
            shop_tiers_id {
            id
            value
        }
    }
        
}   
      `

      const data = await fetchGraphQLData(query, {})
      return data
    },
  }

  inject('graphQL', graphQL)
  // inject('directusImg', directusImg)
  // inject('directusSrcSet', directusSrcSet)
}
