import forge from "node-forge"; // Make sure to import forge or any relevant libraries

export const encrypt = {
generateKeysAndEncrypt(inputData) {
  // console.log("input_data",inputData)
  console.log('NUXT_ENV_PUBLIC_KEY', process.env.NUXT_ENV_PUBLIC_KEY)
  // Encrypt and Decrypt data
  const publicKey = forge.pki.publicKeyFromPem(process.env.NUXT_ENV_PUBLIC_KEY);
  console.log('publicKey', publicKey);
  // const privateKey = this.importPrivateKey(this.privateKeyPem);
  const dataBytes = forge.util.createBuffer(inputData, "utf8");
  const encrypted = publicKey.encrypt(dataBytes.getBytes(), "RSA-OAEP", {
    md: forge.md.sha256.create(),

    mgf1: {
      md: forge.md.sha256.create(),
    },
  });

  console.log("this.encryptedData: ", this.encryptedData);
  return forge.util.encode64(encrypted);
}
}
export default (ctx, inject) => {
  inject('encrypt', encrypt)
}