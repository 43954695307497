/* eslint-disable */
import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'

for (const rule in rules) {
  extend(rule, {
    ...rules[rule],
    // message: en.messages[rule],
  })
}

Vue.component('validationProvider', ValidationProvider)
Vue.component('validationObserver', ValidationObserver)
