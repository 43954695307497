import * as braze from '@braze/web-sdk'
// import braze from '@braze/web-sdk'

export default ({ app }, inject) => {
  // Initialize Braze
  braze.initialize(process.env.NUXT_ENV_BRAZE_KEY, {
    baseUrl: process.env.NUXT_ENV_BRAZE_BASE,
    enableLogging: true,
    minimumIntervalBetweenTriggerActionsInSeconds: 5,
    allowUserSuppliedJavascript: true,
  })
  // Start the Braze session
  braze.openSession()
  // Method to set user and attributes
  // const user = braze.getUser()
  //   const userInfo = app.store.getters['user/userInfo']
  const brazeGtmOptedIn = (opt, position) => {
    if (app.$gtm) {
      console.log(`Notification ${opt} ${position}`)
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'webpush_opted_in',
        ecommerce: {
          ...app.store.getters['user/userInfoTrack'],
          Opted_in: opt,
        },
      })
    }
  }
  const setUser = (userInfo, profileType, userProfile) => {
    // let phone_number = ''
    console.log('braze setup user', userInfo)
    if (Object.keys(userInfo).length) {
      let userId = userInfo.user_open_id
      // profileType === 'standard'
      //   ? userInfo.user_open_id + '_' + userProfile.offline_id
      //   : userInfo.user_open_id + '_' + userProfile.company_code

      braze.changeUser(userId)
      braze
        .getUser()
        .setFirstName(userInfo?.first_name?.[app.store.state.locale])
      braze.getUser().setLastName(userInfo?.last_name?.[app.store.state.locale])
      braze.getUser().setEmail(userInfo?.email)
      // if (userInfo.phones.find((a) => a.type === 'Mobile')) {
      //   phone_number = userInfo.phones.find((a) => a.type === 'Mobile').value
      // }
      // //   user.setProfileType(profileType)
      // user.setPhoneNumber(phone_number)
      // //   braze.logCustomEvent('user_setup_complete')
      // if (!braze.isPushPermissionGranted() && !braze.isPushBlocked()) {
      //   // setIsPushPromptEligible(true) // Example: Update state if permission is not granted and not blocked
      // }
    }
  }

  // Method to clear user data
  const setGuest = (guestId) => {
    braze.wipeData()
    // braze.changeUser(guestId)
  }
  // Function to request push permission
  const brazeRequestPushPermission = () => {
    braze.requestPushPermission(
      () => {
        brazeGtmOptedIn(true, 'from Request')
      },
      (temporary) => {
        brazeGtmOptedIn(false, 'from Request')
      }
    )
  }

  // Call brazeRequestPushPermission initially or as needed
  brazeRequestPushPermission()

  // in-app
  braze.automaticallyShowInAppMessages()
  // Function to subscribe to in-app messages
  const subscribeToInAppMessage = (callback) => {
    braze.subscribeToInAppMessage(function (inAppMessage) {
      console.log('braze InAppMessage', inAppMessage)
      if (inAppMessage instanceof braze.InAppMessage) {
        if (inAppMessage.isControl) {
          braze.logInAppMessageImpression(inAppMessage)
        } else {
          const extras = inAppMessage.extras
          if (extras) {
            for (const key in extras) {
              if (key === 'display') {
                //  && extras[key] === 'homepage'
                braze.showInAppMessage(inAppMessage)
              }
            }
          }
        }
      }
    })
  }
  subscribeToInAppMessage()

  // create content card to element id
  // braze.showContentCards(document.getElementById('standard-feed'));

  // start check notification permission
  // const brazeCheckNotificationPermission = () => {
  //   if (Notification.permission === 'granted') {
  //     brazeGtmOptedIn(true, 'from home')
  //   } else if (Notification.permission === 'denied') {
  //     brazeGtmOptedIn(false, 'from home')
  //   }
  // }
  // const handlePermissionChange = () => {
  //   brazeCheckNotificationPermission()
  //   document.addEventListener('visibilitychange', () => {
  //     if (document.visibilityState === 'visible') {
  //       brazeCheckNotificationPermission()
  //     }
  //   })
  // }
  // handlePermissionChange()

  // Expose the function to subscribe to in-app messages
  inject('subscribeToInAppMessage', subscribeToInAppMessage)
  inject('braze', braze)
  inject('setBrazeUser', setUser)
  inject('setBrazeGuestUser', setGuest)
  inject('brazeGtmOptedIn', brazeGtmOptedIn)
  // inject('subscribeToInAppMessage', subscribeToInAppMessage)

  // check notification permission
  inject('brazeRequestPushPermission', brazeRequestPushPermission)
  // inject('brazeCheckNotificationPermission', brazeCheckNotificationPermission)
}
