import * as core from '@/plugins/service/core'

export const orderProvider = {
  async createOrder(body) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders`,
      body
    )
    return data
  },
  async getOrderDocument(
    orderId,
    userOpenId,
    profileId,
    isAdmin,
    isExportPurchasePdf = false
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}?user_open_id=${userOpenId}&profile_id=${profileId}&is_admin=${isAdmin}&is_export_purchase_pdf=${isExportPurchasePdf}`
    )
    return data
  },
  async getRePaymentCreditCardOrderDocument(
    orderId,
    userOpenId,
    profileId,
    isAdmin,
    isExportPurchasePdf = false
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/head/repayment/creditcard?user_open_id=${userOpenId}&profile_id=${profileId}&is_admin=${isAdmin}&is_export_purchase_pdf=${isExportPurchasePdf}`
    )
    return data
  },
  async getOrderBudget(budgetId, companyId, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/budget/${budgetId}?company_id=${companyId}`,
      null,
      null,
      showLoading
    )
    return data
  },
  async getOrderBudgetFromMail(
    budgetId,
    companyId,
    verifyKey,
    otp_prove_token
  ) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'Get',
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_ORDER}api/v2/Orders/budget/${budgetId}?company_id=${companyId}&verify_key=${verifyKey}`,
    })
    return data
  },
  async approveOrders(body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/approval/information/form_email`,
      body
    )
    return data
  },
  async approveOrderFromMail(otp_prove_token, body) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'put',
      data: body,
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_ORDER}api/v2/Orders/approval/information/form_email`,
    })
    return data
  },
  async getOrderDocumentFromEmailNew(
    orderId,
    verifyKey,
    isExportPurchasePdf = false,
    otp_prove_token,
    otp_channel
  ) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'Get',
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${
        process.env.NUXT_ENV_SERVICE_ORDER
      }api/v2/Orders/${orderId}/from_email?verify_key=${verifyKey}&is_export_purchase_pdf=${isExportPurchasePdf}&is_otp_proved
=${otp_channel !== 'QRCode' ? true : false}`,
    })
    return data
  },
  async getOrderDocumentFromEmail(
    orderId,
    verifyKey,
    isExportPurchasePdf = false
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/from_email?verify_key=${verifyKey}&is_export_purchase_pdf=${isExportPurchasePdf}`
    )
    return data
  },
  async getVerifyOrderStatusFromEmail(verifyKey, body) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v2/Orders/verifyorderstatus?verify_key=${verifyKey}`,
      body
    )
    return data
  },
  async updateOrderDocument(orderObjectId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderObjectId}`,
      body
    )
    return data
  },

  updateOrderPODetail(orderId, body, config) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/po/detail`,
      body,
      config,
      false
    )
    return data
  },
  async test2c2p(body, config) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/test2c2p`,
      body,
      config
    )
    return data
  },

  async get2c2p123Agent(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/payment/2c2p123/agents`,
      params,
      null,
      false
    )
    return data
  },
  async updateOrderDocumentStatus(orderId, status, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/status/${status}`,
      body
    )
    return data
  },
  async updateOrderGoodsRecieveBySubOrder(orderId, subOrderId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/goodsrecieve/${subOrderId}`,
      body
    )
    return data
  },
  async updateOrderGoodsRecieve(orderId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/goodsrecieve`,
      body
    )
    return data
  },
  async checkGoodsReceivePeroid(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/orders/company/${company_id}/checkGoodsReceivePeroid`
    )
    return data
  },
  async reCalulateOrderDocumentHeadCreditCard(
    orderId,
    params,
    loadingSatate = true
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/head/recalulate/creditcard`,
      params,
      null,
      loadingSatate
    )
    return data
  },
  async getPaymentCashOnDelivery(orderId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/payment/cashondelivery`
    )
    return data
  },
  async getOrderActivityLogs(orderId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/OrdersActivityLog/${orderId}`,
      null,
      null,
      false
    )
    return data
  },
  async getOrderActivityFromMailLogs(
    orderId,
    verifyKey,
    otp_prove_token,
    otp_channel
  ) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'Get',
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${
        process.env.NUXT_ENV_SERVICE_ORDER
      }api/v2/OrdersActivityLog/${orderId}/activitylogs?verify_key=${verifyKey}&is_otp_proved
=${otp_channel !== 'QRCode' ? true : false}`,
    })
    // const data = core.fetch(
    //   `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/OrdersActivityLog/${orderId}`,
    //   null,
    //   null,
    //   false
    // )
    return data
  },
  async createApproveGoodsRecieveAll(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/GoodsReceived/orders`,
      params
    )
    return data
  },
  async createApproveGoodsRecieveBySubOrder(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/GoodsReceived/suborder`,
      params
    )
    return data
  },
  async getOrderDocumentGRFromEmail(order_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/GoodsReceived/${order_id}/from_email`,
      params
    )
    return data
  },
  async getOrderActivityGRLogs(order_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/GoodsReceived/${order_id}/orderativitylog`,
      null,
      null,
      false
    )
    return data
  },
  async updateGoodsReceivedApproveOrder(params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/GoodsReceived/approveorder`,
      params
    )
    return data
  },
  async updateOrderPaymentDetail(orderId, body) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/${orderId}/payment/repayment`,
      body
    )
    return data
  },
  async searchOrders(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders`,
      params
    )
    return data
  },
  async create2c2pPayment(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/payments/2c2p`,
      params
    )
    return data
  },
  async paymentInquiry2C2P(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/payments/2c2p/PaymentInquiry`,
      params
    )
    return data
  },
  async create2c2p123Payment(body) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/payment/2c2p123`,
      body
    )
    return data
  },
  async get2c2p123PaymentDetail(paymentCode) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_ORDER}api/v1/Orders/payment/2c2p123/${paymentCode}`
    )
    return data
  },
}

export default (context, inject) => {
  inject('orderProvider', orderProvider)
}
