import * as core from '@/plugins/service/core'

export const logisticProvider = {
  async getZone(textSearch) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_LOGISTIC}api/v1/Zones`,
      textSearch,
      false,
      false
    )
    return data
  },
}
export default (context, inject) => {
  inject('logisticProvider', logisticProvider)
}
