const Cookie = process.client ? require('js-cookie') : undefined
export const state = () => ({
  cartCount: 0,
  skuCount: 0,
  cartCountPrinting: 0,
  cart: [],
  cartId: '',
})

export const mutations = {
  SET_CART_ID(state, cartId) {
    state.cartId = cartId
    Cookie.set('cartId', cartId, { expires: 2, sameSite: 'none', secure: true })
  },
  LOAD_CART_ID(state, cartId) {
    if (this.$utils.isValid(cartId)) state.cartId = cartId
  },
  ADD_CART(state, data) {
    console.log('state.cart', state.cart)
    let item = state.cart.find((q) => q.productId === data.productId)
    if (state.cart.length && item) {
      item.qty += parseInt(data.qty)
    } else {
      state.cart.push({
        productId: data.productId,
        qty: parseInt(data.qty),
      })
    }
    state.cartCount += parseInt(data.qty)
    Cookie.set('cartCount', state.cartCount, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
    Cookie.set('cart', state.cart, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
  },
  CHANGE_QTY(state, data) {
    let item = state.cart.find((q) => q.productId === data.productId)
    let diffQty = data.qty - item.qty //new - old
    item.qty = item.qty + diffQty
    state.cartCount = state.cartCount + diffQty
    Cookie.set('cartCount', state.cartCount, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
    Cookie.set('cart', state.cart, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
  },
  SET_CART_COUNT(state, count) {
    state.cartCount = count.cart_count
    state.skuCount = count.sku_count
  },
  SET_CART_COUNT_PRINTING(state, count) {
    state.cartCountPrinting = count
  },
  LOAD_CART(state, item) {
    if (item.length) state.cart = JSON.parse(item)
  },
  DELETE_ITEM(state, data) {
    let index = state.cart.findIndex((q) => q.productId === data.productId)
    // console.log('index', index)
    state.cart.splice(index, 1)
    state.cartCount -= data.qty
    Cookie.set('cartCount', state.cartCount, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
    Cookie.set('cart', state.cart, {
      expires: 2,
      sameSite: 'none',
      secure: true,
    })
  },
}

export const actions = {
  addCart({ commit }, payload) {
    commit('ADD_CART', payload)
  },
  changeQty({ commit }, payload) {
    commit('CHANGE_QTY', payload)
  },
  deleteItem({ commit }, payload) {
    commit('DELETE_ITEM', payload)
  },
  setCartId({ commit }, payload) {
    commit('SET_CART_ID', payload)
  },
  setCartCount({ commit }, payload) {
    commit('SET_CART_COUNT', payload)
  },
  setCartCountPrinting({ commit }, payload) {
    commit('SET_CART_COUNT_PRINTING', payload)
  },
}

export const getters = {
  cartCount: (state) => state.cartCount,
  skuCount: (state) => state.skuCount,
  cartCountPrinting: (state) => state.cartCountPrinting,
  cart: (state) => state.cart,
  cartId: (state) => state.cartId,
}
