import * as core from '@/plugins/service/core'

export const reportProvider = {
  async getSummaryCostCenterById(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorder`,
      params
    )
    return data
  },
  async getSummaryCostCenterAllById(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorderall`,
      params
    )
    return data
  },
  async getCostCenterCodeById(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/costcentercode`,
      params
    )
    return data
  },
  async getSummaryOrderSatatusData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorderstatus`,
      params
    )
    return data
  },
  async getSummaryOrderStatusAllData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorderstatusall`,
      params
    )
    return data
  },
  async getBudgetControlData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summarybudget`,
      params
    )
    return data
  },
  async getsummaryCategoriesData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summarycategories`,
      params
    )
    return data
  },
  async getOrderReportData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/orderreport`,
      params
    )
    return data
  },
  async getCategoriesMasterData() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/cateriesmaster`
    )
    return data
  },
  async getCateriesReportData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/cateriesreport`,
      params
    )
    return data
  },
  async getBudgetReportData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/budgetreport`,
      params
    )
    return data
  },
  async getBudgetLogsReportData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/budgetlogsreport`,
      params
    )
    return data
  },
  async getOrderUserDashboardData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorderuser`,
      params
    )
    return data
  },
  async getOrderUserOnlyDashboardData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Dashboard/summaryorderuseronly`,
      params
    )
    return data
  },
  async getGoodsReceived(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_REPORT}api/v1/Report/goodsreceivedreport`,
      params
    )
    return data
  },
}
export default (context, inject) => {
  inject('reportProvider', reportProvider)
}
