import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', (value) => {
  if (!value) return ''
  let date = moment(value, 'YYYY/MM/DD HH:mm:ss ZZ').format('DD/MM/YYYY HH:mm')
  if (date === '01/01/0001 00:00' || date === '01/01/1900 00:00') return '-'
  return date
})
Vue.filter('formatDateOnly', (value) => {
  if (!value) return ''
  let date = moment(value, 'YYYY/MM/DD').format('DD/MM/YYYY')
  if (date === '01/01/0001 00:00' || date === '01/01/1900 00:00') return '-'
  return date
})
Vue.filter('formatTimeA', (value) => {
  if (!value) return ''
  let time = moment(value, 'YYYY/MM/DD HH:mm:ss ZZ').format('HH:mm A')
  if (time === '00:00') return '-'
  return time
})
Vue.filter('displayData', function (data) {
  if (data === '' || data === undefined || data === null) return '-'
  else return data
})
Vue.filter('formatDateTH', (value) => {
  if (!value) return ''
  let date = moment(value, 'YYYY/MM/DD HH:mm:ss ZZ').format('DD/MM/YYYY HH:mm')
  if (date === '01/01/0001 00:00' || date === '01/01/1900 00:00') return '-'
  let day = moment(value).format('DD')
  let month = moment(value).format('MM')
  let year = moment(value).format('YYYY')
  let time = moment(value).format('HH:mm:ss')
  const monthNames = [
    $nuxt.$t('mm.01'),
    $nuxt.$t('mm.02'),
    $nuxt.$t('mm.03'),
    $nuxt.$t('mm.04'),
    $nuxt.$t('mm.05'),
    $nuxt.$t('mm.06'),
    $nuxt.$t('mm.07'),
    $nuxt.$t('mm.08'),
    $nuxt.$t('mm.09'),
    $nuxt.$t('mm.10'),
    $nuxt.$t('mm.11'),
    $nuxt.$t('mm.12'),
  ]
  date =
    day + ' ' + monthNames[month - 1] + ' ' + (Number(year) + 543) + ' ' + time
  return date
})
Vue.filter('formatDateEN', (value) => {
  if (!value) return ''
  let date = moment(value, 'YYYY/MM/DD HH:mm:ss ZZ').format('DD/MM/YYYY HH:mm')
  if (date === '01/01/0001 00:00' || date === '01/01/1900 00:00') return '-'
  let day = moment(value).format('DD')
  let month = moment(value).format('MM')
  let year = moment(value).format('YYYY')
  const monthNames = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augest',
    'September',
    'October',
    'November',
    'Decumber',
  ]
  date = monthNames[month - 1] + ' ' + day + ', ' + year
  return date
})
